import React, { useEffect, useRef, useState } from "react";
import ProgressBar from "../components/ProgressBar.js";
import PersonalDetails from "../components/PersonalInformation.js";
import Lifestyle from "../components/Lifestyle";
import Prakrti from "../components/Prakrti";
import HairType from "../components/HairType";
import Submit from "../components/Submit";
import Header from "../components/header.js";
import Footer from "../components/footer.js";
import "../components/quiz.css";
import axios from "axios";
import Loader from "../images/loader.svg"
import { useNavigate, Link } from "react-router-dom";
import moengage from '@moengage/web-sdk';


function Home() {

  const [homeutmLink, setHomeUtmLink] = useState('');

  useEffect(() => {
    const queryString = window.location.search;
    console.log("queryStringewew", queryString.split("?")[1])
    let UTM = queryString.split("?")[1];
    console.log("utm", UTM);
    setHomeUtmLink(UTM);
  }, []);

  let abc = useRef()
  useEffect(() => {
    const initializeMoEngage = async () => {
      try {
        await moengage.initialize({ app_id: '00NX753TB9KUN4ILW7FME8NE', debug_logs: 0, cluster: 'DC_3' });
        console.log('MoEngage initialized successfully');
      } catch (error) {
        console.error('Error initializing MoEngage', error);
      }
    };

    initializeMoEngage();

    return () => {
      // Cleanup logic if needed
    };
  }, []);

  const [currentStep, setCurrentStep] = useState(1);
  const [userDetails, setUserDetails] = useState({});
  const [hairDetails, sethairDetails] = useState({});
  const [PrakrtiDetails, setPrakrtiDetails] = useState({});
  const [lifestyleDetails, setlifestyleDetails] = useState({});
  const [responses, setResponses] = useState({});
  const [loading, setloading] = useState(false);
  const [ResultValue, setResultValue] = useState([]);
  const totalSteps = 4; // Total number of steps


  let navigate = useNavigate();

  const onNext = () => {
    window.onscroll = function() {scrollFunction()};
    function scrollFunction() {}
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  };

  const onPrevious = () => {
    window.onscroll = function() {scrollFunction()};
    function scrollFunction() {}
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleUserDetails = (details) => {
    setUserDetails(details);
    console.log("detailss", details)
    Moengage.track_event("PersonalDetails", {
      "Name": details.name,
      "Email": details.email,
      "Phone": details.phone,
      "Age": details.age,
      "Gender": details.selectedGender,
      // "AgeConsent": details.check,
      "MarketingConsent": details.check1,
    });
    Moengage.add_user_name(details.name);
    Moengage.add_email(details.email);
    Moengage.add_mobile(details.phone);
    Moengage.add_gender(details.selectedGender);
    Moengage.add_user_attribute("Age", details.age);


  };
  const handleHairDetails = (details) => {
    sethairDetails(details);
    const history = localStorage.getItem('history');
    Moengage.track_event("HairType", {
      "HairConcern": details.hairconcerndata,
      "HairfallProblem": history,
      "HairConcernStage": details.stageofconcerndata,
      "HairShape": details.hairtypedata,
      "HairStrands": details.hairstrandsdata,
    });
  };
  const handlePrakrtiDetails = (details) => {
    console.log("handlePrakrtiDetails>>>", details);
    setPrakrtiDetails(details);
    Moengage.track_event("Prakriti", {
      "BodyType": details.bodyType,
      "SkinType": details.skinType,
      "HairType": details.hairType,
      "Appetite": details.appetite,
      "Digestion": details.digestion,
      "Stool": details.stool,
      "Sensitivity": details.sensitivity,
      "Sleep": details.sleep,
      "ActivityRate": details.activity,
      "Endurance": details.endurance
    });
  };
  const handlelifestyleDetails = (details) => {
    console.log("handlelifestyleDetails>>", details)
    setlifestyleDetails(details);
    Moengage.track_event("Lifestyle", {
      "VitaminDeficiency": details.vitamin,
      "BloodPressure": details.bloodpreasure,
      "StressLevels": details.stress,
      "SaltyFoodIntake": details.saltyfood
    });
  };

  const handleResponse = (question, response) => {
    setResponses({ ...responses, [question]: response });
  };

  const dataToSend = { userDetails, hairDetails, PrakrtiDetails, lifestyleDetails };
  const info = useRef();
  info.current = dataToSend;

  console.log("Data to be submitted:", dataToSend);


  const handleSubmit = () => {
    setloading(true);
    let data = JSON.stringify({

      "name": info.current.userDetails?.name,
      "email": info.current.userDetails?.email,
      "phone": info.current.userDetails?.phone,
      "age": info.current.userDetails?.age,
      "gender": info.current.userDetails?.selectedGender,
      "hairConcern": info.current.hairDetails?.hairconcerndata,
      "stage": info.current.hairDetails?.stageofconcerndata,
      // "familtyProblem": hairDetails?.hairhistorydata !=='' || hairDetails?.hairhistorydata !== null? hairDetails?.hairhistorydata : 'No',
      "familtyProblem": 'No',
      "hairShape": info.current.hairDetails?.hairtypedata,
      "hairStrand": info.current.hairDetails?.hairstrandsdata,
      "bodyType": info.current.PrakrtiDetails?.bodyType,
      "skin": info.current.PrakrtiDetails?.skinType,
      "hair": info.current.PrakrtiDetails?.hairType,
      "appetite": info.current.PrakrtiDetails?.appetite,
      "digestion": info.current.PrakrtiDetails?.digestion,
      "stool": info.current.PrakrtiDetails?.stool,
      "sensitive": info.current.PrakrtiDetails?.sensitivity,
      "sleep": info.current.PrakrtiDetails?.sleep,
      "activity": info.current.PrakrtiDetails?.activity,
      "endurance": info.current.PrakrtiDetails?.endurance,
      "vitaminDeficiency": info.current.lifestyleDetails?.vitamin,
      "bloodPressure": info.current.lifestyleDetails?.bloodpreasure,
      "stressLevel": info.current.lifestyleDetails?.stress,
      "saltyFood": info.current.lifestyleDetails?.saltyfood
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.indulekha.co.in/hairtest/quiz',
      headers: {
        'Content-Type': 'application/json'
      },

      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        let responseid = response?.data?.User?._id;
        const data = response.data;
        console.log("data.User>>", data.User)
        setResultValue(data.User)

        const entries = Object.entries(data.User.Prakriti);
        const maxEntry = entries.map(([key, value]) => ({
          key: key,
          value: value,
        }));
        let maxHeightEntry;
        let maxHeight = -Infinity;

        maxEntry.forEach(entry => {
          const height = parseFloat(entry.value);
          if (!isNaN(height) && height > maxHeight) {
            maxHeight = height;
            maxHeightEntry = entry;
          }
        });
        // console.log(maxHeightEntry.key);

        Moengage.track_event("QuizResult", {
          "Name": data.User.User_Analysis.name,
          "Email": data.User.User_Analysis.email,
          "HairShape": data.User.User_Analysis.hairShape,
          "HairStrand": data.User.User_Analysis.hairStrand,
          "HairConcern": data.User.User_Analysis.hairConcern,
          "HairConcernStage": data.User.User_Analysis.stage,
          "Dosha": maxHeightEntry.key,
          "Vata": data.User.Prakriti.Vata,
          "Pitta": data.User.Prakriti.Pitta,
          "Kapha": data.User.Prakriti.Kapha,
          // "ProductName":data.User.name,
          // "ProductURL":data.User.name,
        });

        //adobe implemetation code
        var ev = {};
        ev.eventInfo = {
          'type': ctConstants.diagtooleve,
        };
        ev.category = { 'primaryCategory': ctConstants.custom }
        ev.subcategory = 'Interest';
        ev.attributes = { 'nonInteractive': { 'nonInteraction': 1 }, "Name": data.User.User_Analysis.name, "Email": data.User.User_Analysis.email, "HairShape": data.User.User_Analysis.hairShape, "Dosha": maxHeightEntry.key, "HairStrand": data.User.User_Analysis.hairStrand, "HairConcern": data.User.User_Analysis.hairConcern, "HairStrand": data.User.User_Analysis.hairStrand, "HairConcernStage": data.User.User_Analysis.stage, 'Vata': data.User.Prakriti.Vata, 'Kapha': data.User.Prakriti.Kapha, 'Pitta': data.User.Prakriti.Pitta };
        digitalData.event.push(ev)
        //End adobe implemetation code

        console.log("responseid", responseid);
        setTimeout(() => {
          if (homeutmLink) {
            window.location.href = `https://www.indulekha.co.in/pages/report?responseid=${responseid}&utm_source=hairquiz&utm_medium=quiz&utm_campaign=indulekha_hair_quiz&${homeutmLink}`;

          } else {
            window.location.href = `https://www.indulekha.co.in/pages/report?responseid=${responseid}&utm_source=hairquiz&utm_medium=quiz&utm_campaign=indulekha_hair_quiz`;
          }
        }, 3000)

        // setloading(false);
      })
      .catch((error) => {
        console.log(error);
        setloading(false);
      });

  }

  return (
    <div>
      <Header />
      <main>
        {loading === true ?
          <div className="loaderContainer">
            <div className="loader">
              <img src={Loader} />
            </div></div> :
          <>
            <ProgressBar currentStep={currentStep} totalSteps={totalSteps} />
            {currentStep === 1 && (
              <PersonalDetails
                onNext={onNext}
                handleUserDetails={handleUserDetails}
              />
            )}
            {currentStep === 2 && (
              <HairType
                onNext={onNext}
                onPrevious={onPrevious}
                handleHairDetails={handleHairDetails}
              />
            )}
            {currentStep === 3 && (
              <Prakrti
                onNext={onNext}
                onPrevious={onPrevious}
                handlePrakrtiDetails={handlePrakrtiDetails}
              />
            )}
            {currentStep === 4 && (
              <Lifestyle
                onNext={onNext}
                onPrevious={onPrevious}
                handlelifestyleDetails={handlelifestyleDetails}
                onSubmit={handleSubmit}

              />
            )}
            {/* {currentStep === 5 && (
        <Submit
          responses={responses}
          userDetails={userDetails}
          onSubmit={handleSubmit}
        />
      )} */}
          </>
        }
      </main>
      <Footer />
    </div>
  );
}

export default Home;