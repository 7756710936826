import React from "react";
import Logo from "../images/Logo.svg";
import Exit from "../images/exit.svg";
import "./header.css";
import { Link, useNavigate } from "react-router-dom";
const Header = () => {
  let navigate = useNavigate();
  const homeButtonClick = () => {
    navigate('/');
};
  const exitHandle = () => {
    const confirmExit = window.confirm("Are you sure you want to leave this page?");
    if (confirmExit) {
      window.location.href = "https://www.indulekha.co.in";
    }
    console.log("Exit");
  };
  return (
    <div className="header-container">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center py-4">
        <Link onClick={homeButtonClick} to="https://www.indulekha.co.in/">
        <img  src={Logo} className="logo-image" />
        </Link>
         
          <button
            className="border-0 bg-transparent shadow"
            onClick={exitHandle}
          >
            <img src={Exit} className="logo-image" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
