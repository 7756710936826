import React, { useEffect, useState } from "react";
import alertcircle from "../images/alert-circle.svg";
import frameimage from "../images/Frame.svg";
import rightarrow from "../images/Vector.svg";
import leftarrow from "../images/Vector2.svg";
import group from "../images/Group.png";
import "./quiz.css";
import cross from "../images/vectorcross.svg";
import yes from '../icons/yes.png'
import no from '../icons/no.png'
import notchecked from '../icons/notchecked.png'
import nostress from '../icons/nostress.png'
import lowstress from '../icons/lowstress.png'
import mediumstress from '../icons/mediumstress.png'
import highstress from '../icons/highstress.png'
import exercisedaily from '../icons/exercisedaily.png'
import sometimeexercise from '../icons/sometimesExercise.png'
import rarexErcise from '../icons/rareexercise.png'
import lowsalt from '../icons/lowsalt.png'
import mediumsalt from '../icons/mediumsalt.png'
import highsalt from '../icons/highsalt.png'
import miniloader from '../images/miniloader.svg'
import { Falsebloodpressure, Falsevitamindefiency, Highintakesaltyfoods, Highstress, Lowintakesaltyfoods, Lowstress, Mediumintakesaltyfoods, Mediumstress, Nostress, Notcheckedbloodpressure, Notcheckedvitamin, Truebloodpressure, Truevitamindefiency } from '../svgLibrary.js';


function Lifestyle({ onPrevious, handlelifestyleDetails, onSubmit }) {
  const [vitamin, setvitamin] = useState("");
  const [bloodpreasure, setbloodpreasure] = useState("");
  const [stress, setstress] = useState("");
  const [saltyfoods, setsaltyfoods] = useState("");
  // const [exercise, setexercise] = useState("");
  const [error, seterror] = useState(false);
  const [loading, setloading] = useState(false)

  const [lifestyleResponse, setlifestyleResponse] = useState({
    vitamin: "",
    bloodpreasure: "",
    stress: "",
    saltyfood: "",
    // exercise: ""
  });


  // console.log(lifestyleResponse)

  const handleNext = () => {
    window.onscroll = function() {scrollFunction()};
    function scrollFunction() {}
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if (!lifestyleResponse.vitamin) {
      seterror(true)
    }
    else if (!lifestyleResponse.bloodpreasure) {
      seterror(true)
    }
    else if (!lifestyleResponse.stress) {
      seterror(true)
    }
    else if (!lifestyleResponse.saltyfood) {
      seterror(true)
    }
    // else if (!lifestyleResponse.exercise) {
    //   seterror(true)
    // }
    else if (lifestyleResponse.vitamin && lifestyleResponse.bloodpreasure && lifestyleResponse.stress && lifestyleResponse.saltyfood) {
      handlelifestyleDetails(lifestyleResponse);
      console.log("lifestyleResponse", lifestyleResponse);
      setloading(true)
    } else {
      alert("Please select all the fields.");
    }

    setTimeout(() => {
      setloading(true)
      onSubmit();

    }, 1000);

  };

  const onchangevitaminDeficency = (id) => {
    localStorage.setItem("vitamindeficency", id)

    setlifestyleResponse((prev) => {
      return {
        ...prev,
        vitamin: id,
      };
    });
    // console.log(id)
    setvitamin((prev) => {
      const vitamindef = Object.fromEntries(
        Object.entries(prev).map(([key, value]) => [key, false])
      );
      vitamindef[id] = !prev[id];
      return vitamindef;
    });
  };

  const onchangebloodpreasure = (id) => {
    localStorage.setItem("bloodpreasure", id)

    setlifestyleResponse((prev) => {
      return {
        ...prev,
        bloodpreasure: id,
      };
    });
    //  console.log(id)
    setbloodpreasure((prev) => {
      const bloodpre = Object.fromEntries(
        Object.entries(prev).map(([key, value]) => [key, false])
      );
      bloodpre[id] = !prev[id];
      return bloodpre;
    });
  };

  const onchangeStreslevel = (id) => {
    localStorage.setItem("stress", id)

    setlifestyleResponse((prev) => {
      return {
        ...prev,
        stress: id,
      };
    });

    // console.log(id)
    setstress((prev) => {
      const stres = Object.fromEntries(
        Object.entries(prev).map(([key, value]) => [key, false])
      );
      stres[id] = !prev[id];
      return stres;
    });
  };

  const onchangeIntakeSaltyFood = (id) => {
    localStorage.setItem("saltyfood", id)

    setlifestyleResponse((prev) => {
      return {
        ...prev,
        saltyfood: id,
      };
    });
    // console.log(id)
    setsaltyfoods((prev) => {
      const saltyfood = Object.fromEntries(
        Object.entries(prev).map(([key, value]) => [key, false])
      );
      saltyfood[id] = !prev[id];
      return saltyfood;
    });
  };

  // const onchangeexercise = (id) => {
  //   localStorage.setItem("exercise", id)

  //   setlifestyleResponse((prev) => {
  //     return {
  //       ...prev,
  //       exercise: id,
  //     };
  //   });
  //   // console.log(id)
  //   setexercise((prev) => {
  //     const exrcise = Object.fromEntries(
  //       Object.entries(prev).map(([key, value]) => [key, false])
  //     );
  //     exrcise[id] = !prev[id];
  //     return exrcise;
  //   });
  // };

  const vitamindeficency = () => {
    let v = Object.values(vitamin);
    //  console.log(hairstrands)
    if (v.length === 0) {
      return false;
    }

    let everyFalse = v.every((item) => item === false);
    if (everyFalse) {
      // console.log("inside 2")
      return false;
    }

    let someTrue = v.every((item) => item === false);
    if (!someTrue) {
      // console.log("inside 3")
      return vitamin;
    }
    return vitamin;
  };

  const blodpreasureproblemfun = () => {
    let v = Object.values(bloodpreasure);

    if (v.length === 0) {
      return false;
    }

    let everyFalse = v.every((item) => item === false);
    if (everyFalse) {
      return false;
    }

    let someTrue = v.every((item) => item === false);
    if (!someTrue) {
      // console.log("inside 3")
      return bloodpreasure;
    }

    return bloodpreasure;
  };

  const stresslevelfun = () => {
    let v = Object.values(stress);
    //  console.log(hairstrands)
    if (v.length === 0) {
      return false;
    }

    let everyFalse = v.every((item) => item === false);
    if (everyFalse) {
      // console.log("inside 2")
      return false;
    }

    let someTrue = v.every((item) => item === false);
    if (!someTrue) {
      // console.log("inside 3")
      return stress;
    }
    return stress;
  };

  const saltyfoodintakefun = () => {
    let v = Object.values(saltyfoods);
    //  console.log(hairstrands)
    if (v.length === 0) {
      return false;
    }

    let everyFalse = v.every((item) => item === false);
    if (everyFalse) {
      // console.log("inside 2")
      return false;
    }

    let someTrue = v.every((item) => item === false);
    if (!someTrue) {
      // console.log("inside 3")
      return saltyfoods;
    }
    return saltyfoods;
  };

  // const exercisefun = () => {
  //   let v = Object.values(exercise);
  //   //  console.log(hairstrands)
  //   if (v.length === 0) {
  //     return false;
  //   }

  //   let everyFalse = v.every((item) => item === false);
  //   if (everyFalse) {
  //     // console.log("inside 2")
  //     return false;
  //   }

  //   let someTrue = v.every((item) => item === false);
  //   if (!someTrue) {
  //     // console.log("inside 3")
  //     return exercise;
  //   }
  //   return exercise;
  // };

  const clearlocalstorage = () => {
    localStorage.clear("username", "userPhone", "useremail", "userage", "gender", "hairconcern", "stage", "hairshape", "hairStrands", "history", "bodyType",
      "skinType", "hairType", "Appetite", "Digestion", "Stool", "Sensitivity", "Sleep", "Activity", "Endurence", "vitamindeficency", "bloodpreasure", "stress", "saltyfood")
  }

  useEffect(() => {
    const vitamindeff = localStorage.getItem("vitamindeficency")
    const bloodpreasure = localStorage.getItem("bloodpreasure")
    const stress = localStorage.getItem("stress")
    const saltyfood = localStorage.getItem("saltyfood")
    // const exercise = localStorage.getItem("exercise")

    if (!lifestyleResponse.vitamin) {
      setlifestyleResponse((prev) => ({
        ...prev,
        vitamin: vitamindeff
      }))
      if (vitamindeff !== null) {
        setvitamin((prev) => {
          const newArrows = Object.fromEntries(
            Object.entries(prev).map(([key, value]) => [key, false])
          );
          newArrows[vitamindeff] = !newArrows[vitamindeff];
          return newArrows;
        });
      }
    }

    if (!lifestyleResponse.bloodpreasure) {
      setlifestyleResponse((prev) => ({
        ...prev,
        bloodpreasure: bloodpreasure
      }))
      if (bloodpreasure !== null) {
        setbloodpreasure((prev) => {
          const newArrows = Object.fromEntries(
            Object.entries(prev).map(([key, value]) => [key, false])
          );
          newArrows[bloodpreasure] = !newArrows[bloodpreasure];
          return newArrows;
        });
      }
    }

    if (!lifestyleResponse.stress) {
      setlifestyleResponse((prev) => ({
        ...prev,
        stress: stress
      }))
      if (stress !== null) {
        setstress((prev) => {
          const newArrows = Object.fromEntries(
            Object.entries(prev).map(([key, value]) => [key, false])
          );
          newArrows[stress] = !newArrows[stress];
          return newArrows;
        });
      }
    }

    if (!lifestyleResponse.saltyfood) {
      setlifestyleResponse((prev) => ({
        ...prev,
        saltyfood: saltyfood
      }))
      if (saltyfood !== null) {
        setsaltyfoods((prev) => {
          const newArrows = Object.fromEntries(
            Object.entries(prev).map(([key, value]) => [key, false])
          );
          newArrows[saltyfood] = !newArrows[saltyfood];
          return newArrows;
        });
      }
    }

    // if (!lifestyleResponse.exercise) {
    //   setlifestyleResponse((prev) => ({
    //     ...prev,
    //     exercise: exercise
    //   }))
    //   if (saltyfood !== null) {
    //     setexercise ((prev) => {
    //       const exrcise = Object.fromEntries(
    //         Object.entries(prev).map(([key, value]) => [key, false])
    //       );
    //       exrcise[saltyfood] = !exrcise[saltyfood];
    //       return exrcise;
    //     });
    //   }
    // }

  }, [])

  return (

    <>
      {loading === true ? (
        <>
          <div className="loaderOverlay"></div>
          <div className="miniloader">
            <img src={miniloader} alt="" srcset="" />
          </div>
        </>
      ) : (
        <div className="container hairQuiz">
          <div className="">
            <div className="d-flex justify-content-between">
              <label className="fs-7 fw-bold">
                1. Do you have any vitamin deficiency?
              </label>
              {/*<img src={alertcircle} className="infoIcon"></img>*/}
            </div>

            <div className="d-flex justify-content-between mt-3">
              <div
                className={
                  vitamindeficency() === false
                    ? "adjudt"
                    : vitamindeficency()["Yes"]
                      ? "adjudt"
                      : "dim adjudt"
                }
                style={{ fontSize: "12px" }}
              >
                <label
                  className={
                    vitamin["Yes"]
                      ? `activebutton imagecontainerdiv`
                      : `bg-white imagecontainerdiv`
                  }
                  htmlFor="Yes"
                >
                  <input
                    className="form-check-input d-none"
                    type="radio"
                    name="Yes"
                    id="Yes"
                    value="Yes"
                    checked={vitamin === "Yes"}
                    onChange={() => onchangevitaminDeficency("Yes")}
                  />
                  <Truevitamindefiency />
                </label>
                Yes
              </div>

              <div
                className={
                  vitamindeficency() === false
                    ? "adjudt"
                    : vitamindeficency()["No"]
                      ? "adjudt"
                      : "dim adjudt"
                }
                style={{ fontSize: "12px" }}
              >
                <label
                  className={
                    vitamin["No"]
                      ? `activebutton imagecontainerdiv`
                      : `bg-white imagecontainerdiv`
                  }
                  htmlFor="No"
                >
                  <input
                    className="form-check-input d-none"
                    type="radio"
                    name="No"
                    id="No"
                    value="No"
                    checked={vitamin === "No"}
                    onChange={(e) => onchangevitaminDeficency("No")}
                  />
                  <Falsevitamindefiency />
                </label>
                No
              </div>

              <div
                className={
                  vitamindeficency() === false
                    ? "adjudt"
                    : vitamindeficency()["Not Checked"]
                      ? "adjudt"
                      : "dim adjudt"
                }
                style={{ fontSize: "12px", marginLeft: "-20px" }}
              >
                <label
                  className={
                    vitamin["Not Checked"]
                      ? `activebutton imagecontainerdiv`
                      : `bg-white imagecontainerdiv`
                  }
                  htmlFor="Not Checked"
                >
                  <input
                    className="form-check-input d-none"
                    type="radio"
                    name="Not Checked"
                    id="Not Checked"
                    value="Not Checked"
                    checked={vitamin === "Not Checked"}
                    onChange={() => onchangevitaminDeficency("Not Checked")}
                  />
                  <Notcheckedvitamin />
                </label>
                Not Checked
              </div>
            </div>

            {
              error && !vitamin ? <p className="errorstyle">*Required</p> : ""
            }
            <hr style={{ border: "1px solid #ffd700" }} />
          </div>

          <div>
            <div className="d-flex justify-content-between">
              <label className="fs-7 fw-bold">
                2. Do you have any blood pressure problems?
              </label>
              {/*<img src={alertcircle} className="infoIcon"></img>*/}
            </div>

            <div className="d-flex justify-content-between mt-3">
              <div
                className={
                  blodpreasureproblemfun() === false
                    ? "adjudt"
                    : blodpreasureproblemfun()["Yes"]
                      ? "adjudt"
                      : "dim adjudt"
                }
                style={{ fontSize: "12px" }}
              >
                <label
                  className={
                    bloodpreasure["Yes"]
                      ? `activebutton imagecontainerdiv`
                      : `bg-white imagecontainerdiv`
                  }
                  htmlFor="BPYes"
                >
                  <input
                    className="form-check-input d-none"
                    type="radio"
                    name="Yes"
                    id="BPYes"
                    value="Yes"
                    checked={bloodpreasure === "Yes"}
                    onChange={() => onchangebloodpreasure("Yes")}
                  />
                  <Truebloodpressure />
                </label>
                Yes
              </div>

              <div
                className={
                  blodpreasureproblemfun() === false
                    ? "adjudt"
                    : blodpreasureproblemfun()["No"]
                      ? "adjudt"
                      : "dim adjudt"
                }
                style={{ fontSize: "12px" }}
              >
                <label
                  className={
                    bloodpreasure["No"]
                      ? `activebutton imagecontainerdiv`
                      : `bg-white imagecontainerdiv`
                  }
                  htmlFor="NoBP"
                >
                  <input
                    className="form-check-input d-none"
                    type="radio"
                    name="No"
                    id="NoBP"
                    value="No"
                    checked={bloodpreasure === "No"}
                    onChange={() => onchangebloodpreasure("No")}
                  />
                  <Falsebloodpressure />
                </label>
                No
              </div>

              <div
                className={
                  blodpreasureproblemfun() === false
                    ? "adjudt"
                    : blodpreasureproblemfun()["Not Checked"]
                      ? "adjudt"
                      : "dim adjudt"
                }
                style={{ fontSize: "12px", marginLeft: "-20px" }}
              >
                <label
                  className={
                    bloodpreasure["Not Checked"]
                      ? `activebutton imagecontainerdiv`
                      : `bg-white imagecontainerdiv`
                  }
                  htmlFor="blodpreasureNotChecked"
                >
                  <input
                    className="form-check-input d-none"
                    type="radio"
                    name="BPNot Checked"
                    id="blodpreasureNotChecked"
                    value="Not Checked"
                    checked={bloodpreasure === "Not Checked"}
                    onChange={() => onchangebloodpreasure("Not Checked")}
                  />
                  <Notcheckedbloodpressure />
                </label>
                Not Checked
              </div>
            </div>

            {
              error && !bloodpreasure ? <p className="errorstyle">*Required</p> : ""
            }
            <hr style={{ border: "1px solid #ffd700" }} />
          </div>

          <div>
            <div className="d-flex justify-content-between">
              <label className="fs-7 fw-bold">3. What is your stress levels?</label>
              {/*<img src={alertcircle} className="infoIcon"></img>*/}
            </div>

            <div className="d-flex justify-content-between mt-3">
              <div
                className={
                  stresslevelfun() === false
                    ? "adjudt"
                    : stresslevelfun()["No Stress"]
                      ? "adjudt"
                      : "dim adjudt"
                }
                style={{ fontSize: "12px" }}
              >
                <label
                  className={
                    stress["No Stress"]
                      ? `activebutton imagecontainerdiv`
                      : `bg-white imagecontainerdiv`
                  }
                  htmlFor="Nostress"
                >
                  <input
                    className="form-check-input d-none"
                    type="radio"
                    name="No Stress"
                    id="Nostress"
                    value="No Stress"
                    checked={stress === "No Stress"}
                    onChange={() => onchangeStreslevel("No Stress")}
                  />
                  <Nostress />
                </label>
                No Stress
              </div>

              <div
                className={
                  stresslevelfun() === false
                    ? "adjudt"
                    : stresslevelfun()["Low"]
                      ? "adjudt"
                      : "dim adjudt"
                }
                style={{ fontSize: "12px" }}
              >
                <label
                  className={
                    stress["Low"]
                      ? `activebutton imagecontainerdiv`
                      : `bg-white imagecontainerdiv`
                  }
                  htmlFor="Low"
                >
                  <input
                    className="form-check-input d-none"
                    type="radio"
                    name="Low"
                    id="Low"
                    value="Low"
                    checked={stress === "Low"}
                    onChange={() => onchangeStreslevel("Low")}
                  />
                  <Lowstress />
                </label>
                Low
              </div>

              <div
                className={
                  stresslevelfun() === false
                    ? "adjudt"
                    : stresslevelfun()["Medium"]
                      ? "adjudt"
                      : "dim adjudt"
                }
                style={{ fontSize: "12px" }}
              >
                <label
                  className={
                    stress["Medium"]
                      ? `activebutton imagecontainerdiv`
                      : `bg-white imagecontainerdiv`
                  }
                  htmlFor="Medium"
                >
                  <input
                    className="form-check-input d-none"
                    type="radio"
                    name="Medium"
                    id="Medium"
                    value="Medium"
                    checked={stress === "Medium"}
                    onChange={() => onchangeStreslevel("Medium")}
                  />
                  <Mediumstress />
                </label>
                Medium
              </div>

              <div
                className={
                  stresslevelfun() === false
                    ? "adjudt"
                    : stresslevelfun()["High"]
                      ? "adjudt"
                      : "dim adjudt"
                }
                style={{ fontSize: "12px" }}
              >
                <label
                  className={
                    stress["High"]
                      ? `activebutton imagecontainerdiv`
                      : `bg-white imagecontainerdiv`
                  }
                  htmlFor="Highstress"
                >
                  <input
                    className="form-check-input d-none"
                    type="radio"
                    name="High"
                    id="Highstress"
                    value="High"
                    checked={stress === "High"}
                    onChange={() => onchangeStreslevel("High")}
                  />
                  <Highstress />
                </label>
                High
              </div>
            </div>

            {
              error && !stress ? <p className="errorstyle">*Required</p> : ""
            }
            <hr style={{ border: "1px solid #ffd700" }} />
          </div>

          <div>
            <div className="d-flex justify-content-between">
              <label className="fs-7 fw-bold">
                4. How often do you intake salty foods?
              </label>
              {/*<img src={alertcircle} className="infoIcon"></img>*/}
            </div>

            <div className="d-flex justify-content-between mt-3">
              <div
                className={
                  saltyfoodintakefun() === false
                    ? "adjudt"
                    : saltyfoodintakefun()["Low"]
                      ? "adjudt"
                      : "dim adjudt"
                }
                style={{ fontSize: "12px" }}
              >
                <label
                  className={
                    saltyfoods["Low"]
                      ? `activebutton imagecontainerdiv`
                      : `bg-white imagecontainerdiv`
                  }
                  htmlFor="saltyfoodLow"
                >
                  <input
                    className="form-check-input d-none"
                    type="radio"
                    name="Low"
                    id="saltyfoodLow"
                    value="Low"
                    checked={saltyfoods === "Low"}
                    onChange={() => onchangeIntakeSaltyFood("Low")}
                  />
                  <Lowintakesaltyfoods />
                </label>
                Low
              </div>

              <div
                className={
                  saltyfoodintakefun() === false
                    ? "adjudt"
                    : saltyfoodintakefun()["Medium"]
                      ? "adjudt"
                      : "dim adjudt"
                }
                style={{ fontSize: "12px" }}
              >
                <label
                  className={
                    saltyfoods["Medium"]
                      ? `activebutton imagecontainerdiv`
                      : `bg-white imagecontainerdiv`
                  }
                  htmlFor="saltyfoodMedium"
                >
                  <input
                    className="form-check-input d-none"
                    type="radio"
                    name="Medium"
                    id="saltyfoodMedium"
                    value="Medium"
                    checked={saltyfoods === "Medium"}
                    onChange={() => onchangeIntakeSaltyFood("Medium")}
                  />
                  <Mediumintakesaltyfoods />
                </label>
                Medium
              </div>

              <div
                className={
                  saltyfoodintakefun() === false
                    ? "adjudt"
                    : saltyfoodintakefun()["High"]
                      ? "adjudt"
                      : "dim adjudt"
                }
                style={{ fontSize: "12px" }}
              >
                <label
                  className={
                    saltyfoods["High"]
                      ? `activebutton imagecontainerdiv`
                      : `bg-white imagecontainerdiv`
                  }
                  htmlFor="saltyfoodHigh"
                >
                  <input
                    className="form-check-input d-none"
                    type="radio"
                    name="High"
                    id="saltyfoodHigh"
                    value="High"
                    checked={saltyfoods === "High"}
                    onChange={() => onchangeIntakeSaltyFood("High")}
                  />
                  <Highintakesaltyfoods />
                </label>
                High
              </div>
            </div>

            {
              error && !saltyfoods ? <p className="errorstyle">*Required</p> : ""
            }
          </div>

          <br />

          <div className="d-flex justify-content-around pb-5">
            <button className="button" onClick={onPrevious}>
              {" "}
              <img src={leftarrow} /> &nbsp;Previous{" "}
            </button>
            <button className="button" onClick={() => { handleNext(), clearlocalstorage() }}>
              {" "}
              Submit &nbsp;
              <img src={rightarrow} />
            </button>
          </div>
        </div>
      )}
    </>

  );
}
export default Lifestyle;
