import React, { useEffect, useState } from "react";
import group from "../images/Group.png";
import alertcircle from "../images/alert-circle.svg";
import frameimage from "../images/Frame.svg";
import rightarrow from "../images/Vector.svg";
import leftarrow from "../images/Vector2.svg";
import "./quiz.css";
// import hairfall from '../icons/hairfall.png'
// import Dandrauff from '../icons/dandrauf.png'
// import Graying from '../icons/graying.png'
// import right from '../icons/right.png'
// import wrong from '../icons/wrong.png'
// import stage1 from '../icons/stage1.png'
// import stage2 from '../icons/stage2.png' 
// import stage3 from '../icons/stage2.png'
// import Straight from '../icons/straight.png'
// import wavy from '../icons/wavy.png'
// import Curly from '../icons/curly.png'
// import Coily from '../icons/coily.png'
// import Fine from '../icons/fine.png'
// import Medium from '../icons/medium.png'
// import Thick from '../icons/thick.png'
import miniLoader from "../images/miniloader.svg";
import { Hairfall, Dandruff, Greying, Truehairfall, Falsehairfall, Stage1, Stage2, Stage3, Stage1dand, Stage2dand, Stage3dand, Straightstrands, Wavy, Curly, Coily, Fine, Medium, Thick } from '../svgLibrary.js'


function HairType({ onNext, onPrevious, handleHairDetails }) {
  const [hairconcern, sethairconcern] = useState("");
  const [stageofconcern, setstageofconcern] = useState("");
  const [hairtype, sethairtype] = useState("");
  const [hairstrands, sethairstrands] = useState("");
  const [hairfallHistory, sethairfallHistory] = useState("");
  const [open, setopen] = useState(false);
  const [error, seterror] = useState(false)
  const [loading, setloading] = useState(false);


  const [hairResponce, sethairResponce] = useState({
    hairconcerndata: "",
    hairhistorydata: "",
    stageofconcerndata: "",
    hairtypedata: "",
    hairstrandsdata: "",
  });
  const AdobeInputError = () => {
    var ev = {};
    ev.eventInfo = {
      'type': ctConstants.trackEvent,
      'eventAction': ctConstants.inputerror,
      'eventLabel': "HairType Input Error",
      'eventValue': 1,
    };
    ev.category = { 'primaryCategory': ctConstants.custom }
    ev.subcategory = 'Error';
    digitalData.event.push(ev)
  }

  // console.log(hairResponce.hairconcerndata)
  const handleNext = () => {
    window.onscroll = function() {scrollFunction()};
    function scrollFunction() {}
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    // Validate the input, you can add more validation as needed
    if (!hairResponce.hairconcerndata) {
      seterror(true)
      AdobeInputError()
    }
    else if (!hairResponce.stageofconcerndata) {
      seterror(true)
      AdobeInputError()
    }
    else if (!hairResponce.hairtypedata) {
      seterror(true)
      AdobeInputError()
    }
    else if (!hairResponce.hairstrandsdata) {
      seterror(true)
      AdobeInputError()
    }
    else if (hairResponce.hairconcerndata && hairResponce.stageofconcerndata && hairResponce.hairtypedata && hairResponce.hairstrandsdata) {
      handleHairDetails(hairResponce);
      onNext();
      var ev = {};
      ev.eventInfo = {
        'type': ctConstants.trackEvent,
        'eventAction': ctConstants.linkClick,
        'eventLabel': `Hair Details- ${hairResponce.hairconcerndata},${hairResponce.stageofconcerndata},${hairResponce.hairtypedata},${hairResponce.hairstrandsdata}`,
        'eventValue': 1,

      };
      ev.category = { 'primaryCategory': ctConstants.engagement }
      ev.subcategory = 'Read';
      digitalData.event.push(ev)

    }
    setTimeout(() => {
      setloading(false);
    }, 1000);

    // else {
    //   alert("Please select in all the fields");
    // }
  }

  const onchangehairConcern = (id) => {
    // console.log(id)
    localStorage.setItem("hairconcern", id)
    sethairResponce((prev) => {
      return {
        ...prev,
        hairconcerndata: id,
      };
    });

    sethairconcern((prev) => {
      const newArrows = Object.fromEntries(
        Object.entries(prev).map(([key, value]) => [key, false])
      );
      newArrows[id] = !prev[id];
      // console.log(newArrows)
      return newArrows;
    });
  };


  const onchangeStageOfConcern = (id) => {
    localStorage.setItem("stage", id)
    sethairResponce((prev) => {
      return { ...prev, stageofconcerndata: id };
    });
    // console.log(hairResponce)

    setstageofconcern((prev) => {
      const newArrows = Object.fromEntries(
        Object.entries(prev).map(([key, value]) => [key, false])
      );
      newArrows[id] = !prev[id];
      return newArrows;
    });
  };

  const onchangekHairType = (id) => {
    localStorage.setItem("hairshape", id)
    sethairResponce((prev) => {
      return { ...prev, hairtypedata: id };
    });
    // console.log(hairResponce)

    sethairtype((prev) => {
      const newArrows = Object.fromEntries(
        Object.entries(prev).map(([key, value]) => [key, false])
      );
      newArrows[id] = !prev[id];
      return newArrows;
    });
  };

  const onchangekHairStrands = (id) => {
    localStorage.setItem("hairStrands", id)

    sethairResponce((prev) => {
      return { ...prev, hairstrandsdata: id };
    });
    // console.log(hairResponce)

    sethairstrands((prev) => {
      const newArrows = Object.fromEntries(
        Object.entries(prev).map(([key, value]) => [key, false])
      );
      newArrows[id] = !prev[id];
      return newArrows;
    });
  };

  const onchangeHairHistory = (id) => {
    localStorage.setItem("history", id)

    sethairfallHistory((prev) => {
      const newArrows = Object.fromEntries(
        Object.entries(prev).map(([key, value]) => [key, false])
      );
      newArrows[id] = !prev[id];
      return newArrows;
      console.log("newArrows>>>", newArrows)
    });
  };



  function hairConcernFun() {
    let v = Object.values(hairconcern);

    if (v.length === 0) {
      return false;
    }

    let everyFalse = v.every((item) => item === false);
    if (everyFalse) {
      // console.log("inside 2")
      return false;
    }

    let someTrue = v.every((item) => item === false);
    if (!someTrue) {
      // console.log("inside 3")
      return hairconcern;
    }
  }

  function stageOfConcernfun() {
    let v = Object.values(stageofconcern);

    if (v.length === 0) {
      return false;
    }

    let everyFalse = v.every((item) => item === false);
    if (everyFalse) {
      // console.log("inside 2")
      return false;
    }

    let someTrue = v.every((item) => item === false);
    if (!someTrue) {
      // console.log("inside 3")
      return stageofconcern;
    }
  }

  function hairTypefun() {
    let v = Object.values(hairtype);
    // console.log(hairtype)
    if (v.length === 0) {
      return false;
    }

    let everyFalse = v.every((item) => item === false);
    if (everyFalse) {
      // console.log("inside 2")
      return false;
    }

    let someTrue = v.every((item) => item === false);
    if (!someTrue) {
      // console.log("inside 3")
      return hairtype;
    }
  }

  function hairStrandsfun() {
    let v = Object.values(hairstrands);
    //  console.log(hairstrands)
    if (v.length === 0) {
      return false;
    }

    let everyFalse = v.every((item) => item === false);
    if (everyFalse) {
      // console.log("inside 2")
      return false;
    }

    let someTrue = v.every((item) => item === false);
    if (!someTrue) {
      // console.log("inside 3")
      return hairstrands;
    }
  }

  const hairHistoryfun = () => {
    let v = Object.values(hairfallHistory);
    if (v.length === 0) {
      return false;
    }

    let everyFalse = v.every((item) => item === false);
    if (everyFalse) {
      // console.log("inside 2")
      return false;
    }

    let someTrue = v.every((item) => item === false);
    if (!someTrue) {
      // console.log("inside 3")
      return hairfallHistory;
    }
  };

  const Concernpopup = () => {
    setopen(true);
  };

  const Concernpopupclose = () => {
    setopen(false);
  };

  useEffect(() => {
    const userhairconcern = localStorage.getItem("hairconcern")
    const userstageofconcern = localStorage.getItem("stage")
    const userhairshape = localStorage.getItem("hairshape")
    const userhairstrands = localStorage.getItem("hairStrands")
    const userhairhistory = localStorage.getItem("history")



    if (!hairResponce.hairconcerndata) {
      sethairResponce((prev) => ({
        ...prev,
        hairconcerndata: userhairconcern,
      }));

      if (userhairconcern !== null) {
        sethairconcern((prev) => {
          const newArrows1 = Object.fromEntries(
            Object.entries(prev).map(([key, value]) => [key, false])
          );
          newArrows1[userhairconcern] = !newArrows1[userhairconcern];
          return newArrows1;
        });
      }
    }
    if (!hairResponce.hairhistorydata) {
      sethairResponce((prev) => ({
        ...prev,
        hairhistorydata: userhairhistory,
      }));
      console.log("userhairhistory>>>", userhairhistory)
      if (userhairhistory !== null) {
        sethairfallHistory((prev) => {
          const newArrows1 = Object.fromEntries(
            Object.entries(prev).map(([key, value]) => [key, false])
          );
          newArrows1[userhairhistory] = !newArrows1[userhairhistory];
          return newArrows1;
        });
      }
      console.log("hairhistorydata>>>", hairResponce.hairhistorydata)
    }
    if (!hairResponce.stageofconcerndata) {
      sethairResponce((prev) => ({
        ...prev,
        stageofconcerndata: userstageofconcern
      }));

      if (userstageofconcern !== null) {
        setstageofconcern((prev) => {
          const newArrows = Object.fromEntries(
            Object.entries(prev).map(([key, value]) => [key, false])
          );
          newArrows[userstageofconcern] = !newArrows[userstageofconcern];
          return newArrows;
        });

      }
    }
    if (!hairResponce.hairtypedata) {
      sethairResponce((prev) => ({
        ...prev,
        hairtypedata: userhairshape
      }));

      if (userhairshape !== null) {
        sethairtype((prev) => {
          const newArrows1 = Object.fromEntries(
            Object.entries(prev).map(([key, value]) => [key, false])
          );
          newArrows1[userhairshape] = !newArrows1[userhairshape];
          return newArrows1;
        });

      }
    }
    if (!hairResponce.hairstrandsdata) {
      sethairResponce((prev) => ({
        ...prev,
        hairstrandsdata: userhairstrands
      }));

      if (userhairstrands !== null) {
        sethairstrands((prev) => {
          const newArrows1 = Object.fromEntries(
            Object.entries(prev).map(([key, value]) => [key, false])
          );
          newArrows1[userhairstrands] = !newArrows1[userhairstrands];
          return newArrows1;
        });

      }

    }
  }, [])
  return (
    <>

      <div className="container hairQuiz">
        <div id="hairfall" >
          <div className="d-flex justify-content-between ">
            <label className="fs-7 fw-bold">1. What is your hair concern?</label>
            {/*<img src={alertcircle} className="infoIcon"></img>*/}
          </div>

          <div className="d-flex justify-content-evenly mt-3">
            <div
              className={
                hairConcernFun() === false
                  ? "adjudt"
                  : hairConcernFun()["hairfall"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px" }}
            >
              <label
                className={
                  hairconcern["hairfall"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="exampleRadios1"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="hairfall"
                  id="exampleRadios1"
                  value="hairfall"
                  checked={hairconcern === "hairfall"}
                  onChange={() =>
                    onchangehairConcern(
                      "hairfall",
                      "1.What is your hair concern?"
                    )
                  }
                  onClick={() => {
                    Concernpopup();
                  }}
                />
                <Hairfall />
              </label>
              Hairfall
            </div>

            <div
              className={
                hairConcernFun() === false
                  ? "adjudt"
                  : hairConcernFun()["dandruff"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px" }}
            >
              <label
                className={
                  hairconcern["dandruff"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="exampleRadios2"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="dandruff"
                  id="exampleRadios2"
                  value="dandruff"
                  checked={hairconcern === "dandruff"}
                  onChange={() => onchangehairConcern("dandruff")}
                  onClick={() => {
                    Concernpopupclose();
                  }}
                />
                <Dandruff />
              </label>
              Dandruff
            </div>
            {/* <div
              className={
                hairConcernFun() === false
                  ? "adjudt"
                  : hairConcernFun()["graying"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "14px" }}
            >
              <label
                className={
                  hairconcern["graying"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="exampleRadios3"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="graying"
                  id="exampleRadios3"
                  value="graying"
                  checked={hairconcern === "graying"}
                  onChange={(e) => onchangehairConcern(e.target.value)}
                  onClick={() => {
                    Concernpopupclose();
                  }}
                />
                <Greying />
              </label>
              Graying
            </div> */}
          </div>

          {
            error && !hairResponce.hairconcerndata ? <p style={{ color: "red", fontSize: "12px" }}>*Required</p> : ""
          }
          <hr style={{ border: "1px solid #ffd700" }} />

          {open ? (
            <>
              <div className={`${hairconcern}` ? "action" : null} id="history">
                <div className="d-flex justify-content-between ">
                  <label className="fs-7 fw-bold">
                    {" "}
                    * Any history of hairfall problem in family?
                  </label>
                  <img src={alertcircle} className="infoIcon"></img>
                </div>

                <div className="d-flex justify-content-evenly mt-3">
                  <div
                    className={
                      hairHistoryfun() === false
                        ? "adjudt"
                        : hairHistoryfun()["Yes"]
                          ? "adjudt"
                          : "dim adjudt"
                    }
                    style={{ fontSize: "12px" }}
                  >
                    <label
                      className={
                        hairfallHistory["Yes"]
                          ? `activebutton imagecontainerdiv`
                          : `bg-white imagecontainerdiv`
                      }
                      htmlFor="Yes"
                    >
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        name="Yes"
                        id="Yes"
                        value="Yes"
                        checked={hairfallHistory === "Yes"}
                        onChange={(e) => onchangeHairHistory(e.target.value)}
                      // onClick={() => { hairConcernClick("Hair Fall") }}
                      />
                      <Truehairfall />
                    </label>
                    Yes
                  </div>

                  <div
                    className={
                      hairHistoryfun() === false
                        ? "adjudt"
                        : hairHistoryfun()["No"]
                          ? "adjudt"
                          : "dim adjudt"
                    }
                    style={{ fontSize: "12px" }}
                  >
                    <label
                      className={
                        hairfallHistory["No"]
                          ? `activebutton imagecontainerdiv`
                          : `bg-white imagecontainerdiv`
                      }
                      htmlFor="No"
                    >
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        name="No"
                        id="No"
                        value="No"
                        checked={hairfallHistory === "No"}
                        onChange={(e) => onchangeHairHistory(e.target.value)}
                      // onClick={() => { hairConcernClick("Dandrauf") }}
                      />
                      <Falsehairfall />
                    </label>
                    No
                  </div>
                </div>
                <hr style={{ border: "1px solid #ffd700" }} />
              </div>
            </>
          ) : (
            <> </>
          )}
        </div>

        <div id="hair-fall-stage">
          <div className="d-flex justify-content-between">
            <label className="fs-7 fw-bold">
              2. Please select hair concern stage?
            </label>
            {/*<img src={alertcircle} className="infoIcon"></img>*/}
          </div>
          {hairconcern.dandruff ? (
            <div className="d-flex justify-content-between mt-3">
              <div
                className={
                  stageOfConcernfun() === false
                    ? "adjudt"
                    : stageOfConcernfun()["Stage 1 - Initial"]
                      ? "adjudt"
                      : "dim adjudt"
                }
                style={{ fontSize: "12px" }}
              >
                <label
                  className={
                    stageofconcern["Stage 1 - Initial"]
                      ? `activebutton imagecontainerdiv`
                      : `bg-white imagecontainerdiv`
                  }
                  htmlFor="stageInitial"
                >
                  <input
                    className="form-check-input d-none"
                    type="radio"
                    name="Stage 1 - Initial"
                    id="stageInitial"
                    value="Stage 1 - Initial"
                    checked={stageofconcern === "Stage 1 - Initial"}
                    onChange={() => onchangeStageOfConcern("Stage 1 - Initial")}
                  />
                  <Stage1dand />
                </label>
                Stage 1-
                <br />
                Initial
              </div>

              <div
                className={
                  stageOfConcernfun() === false
                    ? "adjudt"
                    : stageOfConcernfun()["Stage 2 - Mid"]
                      ? "adjudt"
                      : "dim adjudt"
                }
                style={{ fontSize: "12px" }}
              >
                <label
                  className={
                    stageofconcern["Stage 2 - Mid"]
                      ? `activebutton imagecontainerdiv`
                      : `bg-white imagecontainerdiv`
                  }
                  htmlFor="stageMid"
                >
                  <input
                    className="form-check-input d-none"
                    type="radio"
                    name="Stage 2 - Mid"
                    id="stageMid"
                    value="Stage 2 - Mid"
                    checked={stageofconcern === "Stage 2 - Mid"}
                    onChange={() => onchangeStageOfConcern("Stage 2 - Mid")}
                  />
                  <Stage2dand />
                </label>
                Stage 2 -
                <br />
                Mid
              </div>

              <div
                className={
                  stageOfConcernfun() === false
                    ? "adjudt"
                    : stageOfConcernfun()["Stage 3 - Advance"]
                      ? "adjudt"
                      : "dim adjudt"
                }
                style={{ fontSize: "12px" }}
              >
                <label
                  className={
                    stageofconcern["Stage 3 - Advance"]
                      ? `activebutton imagecontainerdiv`
                      : `bg-white imagecontainerdiv`
                  }
                  htmlFor="stageAdvanced"
                >
                  <input
                    className="form-check-input d-none"
                    type="radio"
                    name="Stage 3 - Advance"
                    id="stageAdvanced"
                    value="Stage 3 - Advance"
                    checked={stageofconcern === "Stage 3 - Advance"}
                    onChange={() => onchangeStageOfConcern("Stage 3 - Advance")}
                  />
                  <Stage3dand />
                </label>
                Stage 3-
                <br />
                Advance
              </div>
            </div>
          ) :
            <div className="d-flex justify-content-between mt-3">
              <div
                className={
                  stageOfConcernfun() === false
                    ? "adjudt"
                    : stageOfConcernfun()["Stage 1 - Initial"]
                      ? "adjudt"
                      : "dim adjudt"
                }
                style={{ fontSize: "12px" }}
              >
                <label
                  className={
                    stageofconcern["Stage 1 - Initial"]
                      ? `activebutton imagecontainerdiv`
                      : `bg-white imagecontainerdiv`
                  }
                  htmlFor="stageInitial"
                >
                  <input
                    className="form-check-input d-none"
                    type="radio"
                    name="Stage 1 - Initial"
                    id="stageInitial"
                    value="Stage 1 - Initial"
                    checked={stageofconcern === "Stage 1 - Initial"}
                    onChange={() => onchangeStageOfConcern("Stage 1 - Initial")}
                  />
                  <Stage1 />
                </label>
                Stage 1-
                <br />
                Initial
              </div>

              <div
                className={
                  stageOfConcernfun() === false
                    ? "adjudt"
                    : stageOfConcernfun()["Stage 2 - Mid"]
                      ? "adjudt"
                      : "dim adjudt"
                }
                style={{ fontSize: "12px" }}
              >
                <label
                  className={
                    stageofconcern["Stage 2 - Mid"]
                      ? `activebutton imagecontainerdiv`
                      : `bg-white imagecontainerdiv`
                  }
                  htmlFor="stageMid"
                >
                  <input
                    className="form-check-input d-none"
                    type="radio"
                    name="Stage 2 - Mid"
                    id="stageMid"
                    value="Stage 2 - Mid"
                    checked={stageofconcern === "Stage 2 - Mid"}
                    onChange={() => onchangeStageOfConcern("Stage 2 - Mid")}
                  />
                  <Stage2 />
                </label>
                Stage 2 -
                <br />
                Mid
              </div>

              <div
                className={
                  stageOfConcernfun() === false
                    ? "adjudt"
                    : stageOfConcernfun()["Stage 3 - Advance"]
                      ? "adjudt"
                      : "dim adjudt"
                }
                style={{ fontSize: "12px" }}
              >
                <label
                  className={
                    stageofconcern["Stage 3 - Advance"]
                      ? `activebutton imagecontainerdiv`
                      : `bg-white imagecontainerdiv`
                  }
                  htmlFor="stageAdvanced"
                >
                  <input
                    className="form-check-input d-none"
                    type="radio"
                    name="Stage 3 - Advance"
                    id="stageAdvanced"
                    value="Stage 3 - Advance"
                    checked={stageofconcern === "Stage 3 - Advance"}
                    onChange={() => onchangeStageOfConcern("Stage 3 - Advance")}
                  />
                  <Stage3 />
                </label>
                Stage 3-
                <br />
                Advance
              </div>
            </div>
          }

          {
            error && !hairResponce.stageofconcerndata ? <p style={{ color: "red", fontSize: "12px" }}>*Required</p> : ""
          }
          <hr style={{ border: "1px solid #ffd700" }} />
        </div>

        <div id="hair-shape">
          <div className="d-flex justify-content-between">
            <label className="fs-7 fw-bold">3. What is your hair shape?</label>
            {/*<img src={alertcircle} className="infoIcon"></img>*/}
          </div>

          <div className="d-flex justify-content-between mt-3">
            <div
              className={
                hairTypefun() === false
                  ? "adjudt"
                  : hairTypefun()["Straight"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px" }}
            >
              <label
                className={
                  hairtype["Straight"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="Straight"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="Straight"
                  id="Straight"
                  value="Straight"
                  checked={hairtype === "Straight"}
                  onChange={() => onchangekHairType("Straight")}
                />
                <Straightstrands />
              </label>
              Straight
            </div>

            <div
              className={
                hairTypefun() === false
                  ? "adjudt"
                  : hairTypefun()["Wavy"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px" }}
            >
              <label
                className={
                  hairtype["Wavy"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="Wavy"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="Wavy"
                  id="Wavy"
                  value="Wavy"
                  checked={hairtype === "Wavy"}
                  onChange={() => onchangekHairType("Wavy")}
                />
                <Wavy />
              </label>
              Wavy
            </div>

            <div
              className={
                hairTypefun() === false
                  ? "adjudt"
                  : hairTypefun()["Curly"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px" }}
            >
              <label
                className={
                  hairtype["Curly"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="Curly"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="Curly"
                  id="Curly"
                  value="Curly"
                  checked={hairtype === "Curly"}
                  onChange={() => onchangekHairType("Curly")}
                />
                <Curly />
              </label>
              Curly
            </div>

            <div
              className={
                hairTypefun() === false
                  ? "adjudt"
                  : hairTypefun()["Coily"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px" }}
            >
              <label
                className={
                  hairtype["Coily"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="Coily"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="Coily"
                  id="Coily"
                  value="Coily"
                  checked={hairtype === "Coily"}
                  onChange={() => onchangekHairType("Coily")}
                />
                <Coily />
              </label>
              Coily
            </div>
          </div>

          {
            error && !hairResponce.hairtypedata ? <p style={{ color: "red", fontSize: "12px" }}>*Required</p> : ""
          }
          <hr style={{ border: "1px solid #ffd700" }} />
        </div>

        <div id="hair-strands">
          <div className="d-flex justify-content-between">
            <label className="fs-7 fw-bold">
              4. How would you describe your hair strands?
            </label>
            {/*<img src={alertcircle} className="infoIcon"></img>*/}
          </div>

          <div className="d-flex justify-content-between mt-3">
            <div
              className={
                hairStrandsfun() === false
                  ? "adjudt"
                  : hairStrandsfun()["Fine"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px" }}
            >
              <label
                className={
                  hairstrands["Fine"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="Fine"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="Fine"
                  id="Fine"
                  value="Fine"
                  checked={hairstrands === "Fine"}
                  onChange={() => onchangekHairStrands("Fine")}
                />
                <Fine />
              </label>
              Fine
            </div>

            <div
              className={
                hairStrandsfun() === false
                  ? "adjudt"
                  : hairStrandsfun()["Medium"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px" }}
            >
              <label
                className={
                  hairstrands["Medium"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="Medium"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="Medium"
                  id="Medium"
                  value="Medium"
                  checked={hairstrands === "Medium"}
                  onChange={() => onchangekHairStrands("Medium")}
                />
                <Medium />
              </label>
              Medium
            </div>

            <div
              className={
                hairStrandsfun() === false
                  ? "adjudt"
                  : hairStrandsfun()["Thick"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px" }}
            >
              <label
                className={
                  hairstrands["Thick"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="Thick"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="Thick"
                  id="Thick"
                  value="Thick"
                  checked={hairstrands === "Thick"}
                  onChange={() => onchangekHairStrands("Thick")}
                />
                <Thick />
              </label>
              Thick
            </div>
          </div>

          {
            error && !hairResponce.hairstrandsdata ? <p style={{ color: "red", fontSize: "12px" }}>*Required</p> : ""
          }

          <br />
          <br />
          <div className="d-flex justify-content-between pb-5">
            <button className="button" onClick={() => { onPrevious() }}>
              {" "}
              <img src={leftarrow} /> &nbsp;Previous{" "}
            </button>
            <button className="button" onClick={handleNext} id="myBtn">
              {" "}
              Next &nbsp;
              <img src={rightarrow} />
            </button>
          </div>
        </div>
        {/* <button onClick={onPrevious}>Previous</button>
    <button onClick={onNext}>Next</button> */}
      </div>




    </>

  );
}

export default HairType;
