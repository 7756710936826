import React from 'react'

export default function FlowStep({step,title,image}) {
    return (
        <div className='wayToUse'>
            <p className="c-step-header d-flex justify-content-center align-items-center flex-column">
                <span className="c-number">{step}</span>
                <span className="c-description">
                {title} 
                </span>
            </p>
            <div className="w-75-custom  ">
                <img src={image} width="100%" alt="Step Image" />
            </div>
        </div>
    )
}

