import React from 'react'
import Layout from '../components/layout';

const Notfound = () => {
  return (
<Layout>
page not found
</Layout>
  )
}

export default Notfound;
