import React, { Children } from "react";
import Header from "./header";

const Layout = ({ children }) => {
  return (
    <div>
      <Header />
      <main role="main">{children}</main>
    </div>
  );
};

export default Layout;
