import logo from "./logo.svg";
import {useEffect, useState} from 'react'
import "./App.css";
import Routes from "./routes";
import 'bootstrap/dist/css/bootstrap.min.css';
import moengage from '@moengage/web-sdk';
// import Adobe from './components/Adobe';

function App() {
  return (
    <>
      {/* <Adobe /> */}
      <Routes />
    </>
  );
}

export default App;
