import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../pages/home";
import Notfound from "../pages/not-found";
import Landing from "../pages/Landing";



const RouterPage = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
        <Route path="/" element={<Landing/>} />
        <Route path="/hairquiz" element={<Home/>} />
        <Route path="*" element={<Notfound/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default RouterPage;