import React, { useEffect, useState } from "react";
import alertcircle from "../images/alert-circle.svg";
import frameimage from "../images/Frame.svg";
import rightarrow from "../images/Vector.svg";
import leftarrow from "../images/Vector2.svg";
import group from "../images/Group.png";
import "./quiz.css";
import cross from "../images/vectorcross.svg";
import { Slimlean, Mediumslim, Broad, Thindrycold, Smoothoily, Thickdrycool, Thindry, Oilypremature, Thickoily, Lowappetite, Strongappetite, Constantappetite, Low, Lowdigestion, Quickdigestion, Prolongeddigestion, Looseprone, Moderate, Coldsensitive, Heatdislike, Light, Moderatesound, Heavypronologed, Quickfast, Mediumgoal, Slowsteady, Poor, Mediumendurance, Goodendurance, Fearofcold } from '../svgLibrary.js'
import slim from '../icons/slim and lean.png'
import meadium from '../icons/medium body.png'
import borad from '../icons/broad.png'
import skinthin from '../icons/thin skin.png'
import smothskin from '../icons/smoth skin.png'
import thickskin from '../icons/hairtypethick.png'
import thinhair from '../icons/hair thin dry.png'
import oilyhair from '../icons/hair oily gary.png'
import thickoily from '../icons/hair thick oily.png'
import appetiteLow from '../icons/appetite low.png'
import appetiteStrong from '../icons/appetite strong.png'
import appetiteConstsntLow from '../icons/appetite constant and low.png'

import lowdigestion from '../icons/lowDigestion.png'
import quickdigestion from '../icons/quickDigestion.png'
import heavydigestion from '../icons/heavyDigestion.png'
import lowstool from '../icons/lowStool.png'
import loosestool from '../icons/looseStool.png'
import moderatestool from '../icons/moderateStool.png'
import coldsenstive from '../icons/sensitiveCold.png'
import heatsensitive from '../icons/sensitiveHot.png'
import fearofCold from '../icons/sensitiveFearCold.png'
import Lightsleep from '../icons/sleepLight.png'
import moderatesleep from '../icons/sleepModerate.png'
import heavysleep from '../icons/sleepHeavy.png'
import activityquick from '../icons/activityQuick.png'
import activitymedium from '../icons/activityMedium.png'
import activityslow from '../icons/activitySlow.png'
import endurancepoor from '../icons/endurencePoor.png'
import endurancemedium from '../icons/endurenceMedium.png'
import endurancesgood from '../icons/endurenceGood.png'
import Pmemory from '../icons/memoryPoor.png'
import Smemory from '../icons/memorySHarp.png'
import slowmwmoey from '../icons/memoryslow.png'
import activemind from '../icons/mindActive.png'
import intelegentmind from '../icons/mindIntelegent.png'
import slowmind from '../icons/mindSlow.png'
import miniloader from '../images/miniloader.svg'

function Prakrti({ onNext, onPrevious, handlePrakrtiDetails }) {

  const [bodyType, setBodyType] = useState("");
  const [skinType, setSkinType] = useState("");
  const [hairType, setHairType] = useState("");
  const [appetite, setAppetite] = useState("");
  const [digestion, setDigestion] = useState("");
  const [stool, setStool] = useState("");
  const [sensitivity, setSensitivity] = useState("");
  const [sleep, setSleep] = useState("");
  const [activity, setActivity] = useState("");
  const [endurance, setEndurance] = useState("");
  // const [memory, setMemory] = useState("")
  // const [mind, setMind] = useState("")
  const [error, seterror] = useState(false)
  const [loading, setloading] = useState(false)

  // console.log(bodyType)
  const [PrakrtiResponse, setPrakrtiResponse] = useState({
    bodyType: "",
    skinType: "",
    hairType: "",
    appetite: "",
    digestion: "",
    stool: "",
    sensitivity: "",
    sleep: "",
    activity: "",
    endurance: "",
    // memory: "",
    // mind: ""
  });
  const AdobeInputError = () => {
    var ev = {};
    ev.eventInfo = {
      'type': ctConstants.trackEvent,
      'eventAction': ctConstants.inputerror,
      'eventLabel': "Prakriti Input Error",
      'eventValue': 1,
    };
    ev.category = { 'primaryCategory': ctConstants.custom }
    ev.subcategory = 'Error';
    digitalData.event.push(ev)
  }
  // console.log( PrakrtiResponse)
  // console.log( PrakrtiResponse.bodyType)
  const handleNext = () => {
    window.onscroll = function() {scrollFunction()};
    function scrollFunction() {}
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    // Validate the input, you can add more validation as needed
    if (!PrakrtiResponse.bodyType) {
      seterror(true)
      AdobeInputError('bodyType')
    } else if (!PrakrtiResponse.skinType) {
      seterror(true)
      AdobeInputError()
    } else if (!PrakrtiResponse.hairType) {
      seterror(true)
      AdobeInputError()
    } else if (!PrakrtiResponse.appetite) {
      seterror(true)
      AdobeInputError()
    } else if (!PrakrtiResponse.digestion) {
      seterror(true)
      AdobeInputError()
    } else if (!PrakrtiResponse.stool) {
      seterror(true)
      AdobeInputError()
    } else if (!PrakrtiResponse.sensitivity) {
      seterror(true)
      AdobeInputError()
    } else if (!PrakrtiResponse.sleep) {
      seterror(true)
      AdobeInputError()
    } else if (!PrakrtiResponse.activity) {
      seterror(true)
      AdobeInputError()
    } else if (!PrakrtiResponse.endurance) {
      seterror(true)
      AdobeInputError()
    }
    // else if(!PrakrtiResponse.memory){
    //   seterror(true)
    // }else if(!PrakrtiResponse.mind){
    //   seterror(true)
    // }

    else if (PrakrtiResponse.bodyType && PrakrtiResponse.skinType && PrakrtiResponse.hairType && PrakrtiResponse.appetite && PrakrtiResponse.digestion && PrakrtiResponse.stool && PrakrtiResponse.sensitivity && PrakrtiResponse.sleep && PrakrtiResponse.activity && PrakrtiResponse.endurance) {
      handlePrakrtiDetails(PrakrtiResponse)
      onNext();
      var ev = {};
      ev.eventInfo = {
        'type': ctConstants.trackEvent,
        'eventAction': ctConstants.linkClick,
        'eventLabel': `Prakriti- ${PrakrtiResponse.bodyType}, ${PrakrtiResponse.skinType}, ${PrakrtiResponse.hairType}, ${PrakrtiResponse.appetite}, ${PrakrtiResponse.digestion}, ${PrakrtiResponse.stool}, ${PrakrtiResponse.sensitivity}, ${PrakrtiResponse.sleep}, ${PrakrtiResponse.activity}, ${PrakrtiResponse.endurance}`,
        'eventValue': 1,
      };
      ev.category = { 'primaryCategory': ctConstants.engagement }
      ev.subcategory = 'Read';
      digitalData.event.push(ev)
      //  console.log("handlePrakrtiDetails", handlePrakrtiDetails);
    }

    setTimeout(() => {
      setloading(false)
    }, 1000);

  };

  const onchangebodytype = (id) => {
    localStorage.setItem("bodyType", id)

    setPrakrtiResponse((prev) => {
      return {
        ...prev,
        bodyType: id,
      };
    });

    // console.log(id)
    setBodyType((prev) => {
      const bodytyp = Object.fromEntries(
        Object.entries(prev).map(([key, value]) => [key, false])
      );
      bodytyp[id] = !prev[id];
      return bodytyp;
    });
  };

  const onchangeSkintype = (id) => {
    localStorage.setItem("skinType", id)

    setPrakrtiResponse((prev) => {
      return {
        ...prev,
        skinType: id,
      };
    });
    //  console.log(id)
    setSkinType((prev) => {
      const skin = Object.fromEntries(
        Object.entries(prev).map(([key, value]) => [key, false])
      );
      skin[id] = !prev[id];
      return skin;
    });
  };

  const onchangehairtype = (id) => {
    localStorage.setItem("hairType", id)

    setPrakrtiResponse((prev) => {
      return {
        ...prev,
        hairType: id,
      };
    });
    // console.log(id)
    setHairType((prev) => {
      const hair = Object.fromEntries(
        Object.entries(prev).map(([key, value]) => [key, false])
      );
      hair[id] = !prev[id];
      return hair;
    });
  };

  const onchangeAppetite = (id) => {
    localStorage.setItem("Appetite", id)

    setPrakrtiResponse((prev) => {
      return {
        ...prev,
        appetite: id,
      };
    });
    // console.log(id)
    setAppetite((prev) => {
      const Appetite = Object.fromEntries(
        Object.entries(prev).map(([key, value]) => [key, false])
      );
      Appetite[id] = !prev[id];
      return Appetite;
    });
  };

  const onchangeDigestion = (id) => {
    localStorage.setItem("Digestion", id)

    setPrakrtiResponse((prev) => {
      return {
        ...prev,
        digestion: id,
      };
    });
    // console.log(id)
    setDigestion((prev) => {
      const digstn = Object.fromEntries(
        Object.entries(prev).map(([key, value]) => [key, false])
      );
      digstn[id] = !prev[id];
      return digstn;
    });
  };

  const onchangestool = (id) => {
    localStorage.setItem("Stool", id)

    setPrakrtiResponse((prev) => {
      return {
        ...prev,
        stool: id,
      };
    });
    // console.log(id)
    setStool((prev) => {
      const stool = Object.fromEntries(
        Object.entries(prev).map(([key, value]) => [key, false])
      );
      stool[id] = !prev[id];
      return stool;
    });
  };

  const onchangeSensitivity = (id) => {
    localStorage.setItem("Sensitivity", id)

    setPrakrtiResponse((prev) => {
      return {
        ...prev,
        sensitivity: id,
      };
    });
    //  console.log(id)
    setSensitivity((prev) => {
      const sensitive = Object.fromEntries(
        Object.entries(prev).map(([key, value]) => [key, false])
      );
      sensitive[id] = !prev[id];
      return sensitive;
    });
  };

  const onchangeSleep = (id) => {
    localStorage.setItem("Sleep", id)

    setPrakrtiResponse((prev) => {
      return {
        ...prev,
        sleep: id,
      };
    });
    // console.log(id)
    setSleep((prev) => {
      const sleep = Object.fromEntries(
        Object.entries(prev).map(([key, value]) => [key, false])
      );
      sleep[id] = !prev[id];
      return sleep;
    });
  };

  const onchangeactiivity = (id) => {
    localStorage.setItem("Activity", id)

    setPrakrtiResponse((prev) => {
      return {
        ...prev,
        activity: id,
      };
    });
    // console.log(id)
    setActivity((prev) => {
      const dailyactivity = Object.fromEntries(
        Object.entries(prev).map(([key, value]) => [key, false])
      );
      dailyactivity[id] = !prev[id];
      return dailyactivity;
    });
  };

  const onchangeendurance = (id) => {
    localStorage.setItem("Endurence", id)

    setPrakrtiResponse((prev) => {
      return {
        ...prev,
        endurance: id,
      };
    });
    // console.log(id)
    setEndurance((prev) => {
      const endurenc = Object.fromEntries(
        Object.entries(prev).map(([key, value]) => [key, false])
      );
      endurenc[id] = !prev[id];
      return endurenc;
    });
  };

  // const onchangememory = (id) => {
  //   localStorage.setItem("Memory", id)

  //   setPrakrtiResponse((prev) => {
  //     return {
  //       ...prev,
  //       memory: id,
  //     };
  //   });
  //   // console.log(id)
  //   setMemory((prev) => {
  //     const memory = Object.fromEntries(
  //       Object.entries(prev).map(([key, value]) => [key, false])
  //     );
  //     memory[id] = !prev[id];
  //     return memory;
  //   });
  // };

  // const onchangemind = (id) => {
  //   localStorage.setItem("Mind", id)

  //   setPrakrtiResponse((prev) => {
  //     return {
  //       ...prev,
  //       mind: id,
  //     };
  //   });
  //   // console.log(id)
  //   setMind((prev) => {
  //     const mind = Object.fromEntries(
  //       Object.entries(prev).map(([key, value]) => [key, false])
  //     );
  //     mind[id] = !prev[id];
  //     return mind;
  //   });
  // };



  // ===========
  const bodytypefun = () => {
    let v = Object.values(bodyType);
    //  console.log(hairstrands)
    if (v.length === 0) {
      return false;
    }

    let everyFalse = v.every((item) => item === false);
    if (everyFalse) {
      // console.log("inside 2")
      return false;
    }

    let someTrue = v.every((item) => item === false);
    if (!someTrue) {
      // console.log("inside 3")
      return bodyType;
    }
    return bodyType;
  };

  const skintypefun = () => {
    let v = Object.values(skinType);
    //  console.log(hairstrands)
    if (v.length === 0) {
      return false;
    }

    let everyFalse = v.every((item) => item === false);
    if (everyFalse) {
      // console.log("inside 2")
      return false;
    }

    let someTrue = v.every((item) => item === false);
    if (!someTrue) {
      // console.log("inside 3")
      return skinType;
    }
    return skinType;
  };

  const hairtypefun = () => {
    let v = Object.values(hairType);
    //  console.log(hairstrands)
    if (v.length === 0) {
      return false;
    }

    let everyFalse = v.every((item) => item === false);
    if (everyFalse) {
      // console.log("inside 2")
      return false;
    }

    let someTrue = v.every((item) => item === false);
    if (!someTrue) {
      // console.log("inside 3")
      return hairType;
    }
    return hairType;
  };

  const appetitefun = () => {
    let v = Object.values(appetite);
    //  console.log(hairstrands)
    if (v.length === 0) {
      return false;
    }

    let everyFalse = v.every((item) => item === false);
    if (everyFalse) {
      // console.log("inside 2")
      return false;
    }

    let someTrue = v.every((item) => item === false);
    if (!someTrue) {
      // console.log("inside 3")
      return appetite;
    }
    return appetite;
  };

  const digestionfun = () => {
    let v = Object.values(digestion);
    //  console.log(hairstrands)
    if (v.length === 0) {
      return false;
    }

    let everyFalse = v.every((item) => item === false);
    if (everyFalse) {
      // console.log("inside 2")
      return false;
    }

    let someTrue = v.every((item) => item === false);
    if (!someTrue) {
      // console.log("inside 3")
      return digestion;
    }
    return digestion;
  };

  const stoolfun = () => {
    let v = Object.values(stool);
    //  console.log(hairstrands)
    if (v.length === 0) {
      return false;
    }

    let everyFalse = v.every((item) => item === false);
    if (everyFalse) {
      // console.log("inside 2")
      return false;
    }

    let someTrue = v.every((item) => item === false);
    if (!someTrue) {
      // console.log("inside 3")
      return stool;
    }
    return stool;
  };

  const sensitivityfun = () => {
    let v = Object.values(sensitivity);
    //  console.log(hairstrands)
    if (v.length === 0) {
      return false;
    }

    let everyFalse = v.every((item) => item === false);
    if (everyFalse) {
      // console.log("inside 2")
      return false;
    }

    let someTrue = v.every((item) => item === false);
    if (!someTrue) {
      // console.log("inside 3")
      return sensitivity;
    }
    return sensitivity;
  };

  const sleepfun = () => {
    let v = Object.values(sleep);
    //  console.log(hairstrands)
    if (v.length === 0) {
      return false;
    }

    let everyFalse = v.every((item) => item === false);
    if (everyFalse) {
      // console.log("inside 2")
      return false;
    }

    let someTrue = v.every((item) => item === false);
    if (!someTrue) {
      // console.log("inside 3")
      return sleep;
    }
    return sleep;
  };

  const Activityfun = () => {
    let v = Object.values(activity);
    //  console.log(hairstrands)
    if (v.length === 0) {
      return false;
    }

    let everyFalse = v.every((item) => item === false);
    if (everyFalse) {
      // console.log("inside 2")
      return false;
    }

    let someTrue = v.every((item) => item === false);
    if (!someTrue) {
      // console.log("inside 3")
      return activity;
    }
    return activity;
  };

  const endurencefun = () => {
    let v = Object.values(endurance);
    //  console.log(hairstrands)
    if (v.length === 0) {
      return false;
    }

    let everyFalse = v.every((item) => item === false);
    if (everyFalse) {
      // console.log("inside 2")
      return false;
    }

    let someTrue = v.every((item) => item === false);
    if (!someTrue) {
      // console.log("inside 3")
      return endurance;
    }
    return endurance;
  };

  // const memoryfun = () => {
  //   let v = Object.values(memory);
  //   //  console.log(hairstrands)
  //   if (v.length === 0) {
  //     return false;
  //   }

  //   let everyFalse = v.every((item) => item === false);
  //   if (everyFalse) {
  //     // console.log("inside 2")
  //     return false;
  //   }

  //   let someTrue = v.every((item) => item === false);
  //   if (!someTrue) {
  //     // console.log("inside 3")
  //     return memory;
  //   }
  //   return memory;
  // };

  // const mindfun = () => {
  //   let v = Object.values(mind);
  //   //  console.log(hairstrands)
  //   if (v.length === 0) {
  //     return false;
  //   }

  //   let everyFalse = v.every((item) => item === false);
  //   if (everyFalse) {
  //     // console.log("inside 2")
  //     return false;
  //   }

  //   let someTrue = v.every((item) => item === false);
  //   if (!someTrue) {
  //     // console.log("inside 3")
  //     return mind;
  //   }
  //   return mind;
  // };

  useEffect(() => {
    const userbodytype = localStorage.getItem("bodyType")
    const userskintype = localStorage.getItem("skinType")
    const userhairtype = localStorage.getItem("hairType")
    const userappetite = localStorage.getItem("Appetite")
    const userdigestion = localStorage.getItem("Digestion")
    const userstool = localStorage.getItem("Stool")
    const usersensitivety = localStorage.getItem("Sensitivity")
    const usersleep = localStorage.getItem("Sleep")
    const useractivity = localStorage.getItem("Activity")
    const userendurance = localStorage.getItem("Endurence")
    // const usermemory = localStorage.getItem("Memory")
    // const usermind = localStorage.getItem("Mind")



    if (!PrakrtiResponse.bodyType) {
      setPrakrtiResponse((prev) => ({
        ...prev,
        bodyType: userbodytype,
      }));

      if (userbodytype !== null) {
        setBodyType((prev) => {
          const newArrows1 = Object.fromEntries(
            Object.entries(prev).map(([key, value]) => [key, false])
          );
          newArrows1[userbodytype] = !newArrows1[userbodytype];
          return newArrows1;
        });
      }
    }
    if (!PrakrtiResponse.skinType) {
      setPrakrtiResponse((prev) => ({
        ...prev,
        skinType: userskintype,
      }));

      if (userskintype !== null) {
        setSkinType((prev) => {
          const newArrows1 = Object.fromEntries(
            Object.entries(prev).map(([key, value]) => [key, false])
          );
          newArrows1[userskintype] = !newArrows1[userskintype];
          return newArrows1;
        });
      }
    }
    if (!PrakrtiResponse.hairType) {
      setPrakrtiResponse((prev) => ({
        ...prev,
        hairType: userhairtype,
      }));

      if (userhairtype !== null) {
        setHairType((prev) => {
          const newArrows1 = Object.fromEntries(
            Object.entries(prev).map(([key, value]) => [key, false])
          );
          newArrows1[userhairtype] = !newArrows1[userhairtype];
          return newArrows1;
        });
      }
    }
    if (!PrakrtiResponse.appetite) {
      setPrakrtiResponse((prev) => ({
        ...prev,
        appetite: userappetite,
      }));

      if (userappetite !== null) {
        setAppetite((prev) => {
          const newArrows1 = Object.fromEntries(
            Object.entries(prev).map(([key, value]) => [key, false])
          );
          newArrows1[userappetite] = !newArrows1[userappetite];
          return newArrows1;
        });
      }
    }
    if (!PrakrtiResponse.digestion) {
      setPrakrtiResponse((prev) => ({
        ...prev,
        digestion: userdigestion,
      }));

      if (userdigestion !== null) {
        setDigestion((prev) => {
          const newArrows1 = Object.fromEntries(
            Object.entries(prev).map(([key, value]) => [key, false])
          );
          newArrows1[userdigestion] = !newArrows1[userdigestion];
          return newArrows1;
        });
      }
    }
    if (!PrakrtiResponse.stool) {
      setPrakrtiResponse((prev) => ({
        ...prev,
        stool: userstool,
      }));

      if (userstool !== null) {
        setStool((prev) => {
          const newArrows1 = Object.fromEntries(
            Object.entries(prev).map(([key, value]) => [key, false])
          );
          newArrows1[userstool] = !newArrows1[userstool];
          return newArrows1;
        });
      }
    }
    if (!PrakrtiResponse.sensitivity) {
      setPrakrtiResponse((prev) => ({
        ...prev,
        sensitivity: usersensitivety,
      }));

      if (usersensitivety !== null) {
        setSensitivity((prev) => {
          const newArrows1 = Object.fromEntries(
            Object.entries(prev).map(([key, value]) => [key, false])
          );
          newArrows1[usersensitivety] = !newArrows1[usersensitivety];
          return newArrows1;
        });
      }
    }
    if (!PrakrtiResponse.sleep) {
      setPrakrtiResponse((prev) => ({
        ...prev,
        sleep: usersleep,
      }));

      if (usersleep !== null) {
        setSleep((prev) => {
          const newArrows1 = Object.fromEntries(
            Object.entries(prev).map(([key, value]) => [key, false])
          );
          newArrows1[usersleep] = !newArrows1[usersleep];
          return newArrows1;
        });
      }
    }
    if (!PrakrtiResponse.activity) {
      setPrakrtiResponse((prev) => ({
        ...prev,
        activity: useractivity,
      }));

      if (useractivity !== null) {
        setActivity((prev) => {
          const newArrows1 = Object.fromEntries(
            Object.entries(prev).map(([key, value]) => [key, false])
          );
          newArrows1[useractivity] = !newArrows1[useractivity];
          return newArrows1;
        });
      }
    }
    if (!PrakrtiResponse.endurance) {
      setPrakrtiResponse((prev) => ({
        ...prev,
        endurance: userendurance,
      }));

      if (userendurance !== null) {
        setEndurance((prev) => {
          const newArrows1 = Object.fromEntries(
            Object.entries(prev).map(([key, value]) => [key, false])
          );
          newArrows1[userendurance] = !newArrows1[userendurance];
          return newArrows1;
        });
      }
    }
    // if(!PrakrtiResponse.memory ){
    //   setPrakrtiResponse((prev) => ({
    //     ...prev,
    //     memory: usermemory,
    //   }));

    //   if (usermemory !== null) {
    //     setMemory((prev) => {
    //       const memory = Object.fromEntries(
    //         Object.entries(prev).map(([key, value]) => [key, false])
    //       );
    //       memory[usermemory] = !memory[usermemory];
    //       return memory;
    //     });
    //   }
    // }
    // if(!PrakrtiResponse.mind ){
    //   setPrakrtiResponse((prev) => ({
    //     ...prev,
    //     mind: usermind,
    //   }));

    //   if (usermind !== null) {
    //     setMind((prev) => {
    //       const mind = Object.fromEntries(
    //         Object.entries(prev).map(([key, value]) => [key, false])
    //       );
    //       mind[usermind] = !mind[usermind];
    //       return mind;
    //     });
    //   }
    // }

  }, [])


  return (
    <>


      <div className="container hairQuiz">
        <div id="userbodytype">
          <div className="d-flex justify-content-between">
            <label className="fs-7 fw-bold">
              1. How would you best describe your body type?
            </label>
            {/*<img src={alertcircle} className="infoIcon"></img>*/}
          </div>

          <div className="d-flex justify-content-between mt-3">
            <div
              className={
                bodytypefun() === false
                  ? "adjudt"
                  : bodytypefun()["slim-and-lean"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "13px" }}
            >
              <label
                className={
                  bodyType["slim-and-lean"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="Slim"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="slim-and-lean"
                  id="Slim"
                  value="slim-and-lean"
                  checked={bodyType === "slim-and-lean"}
                  onChange={() => onchangebodytype("slim-and-lean")}
                />
                <Slimlean />
              </label>
              Slim & Lean
            </div>

            <div
              className={
                bodytypefun() === false
                  ? "adjudt"
                  : bodytypefun()["medium-build"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "13px" }}
            >
              <label
                className={
                  bodyType["medium-build"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="MediumBuild"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="medium-build"
                  id="MediumBuild"
                  value="medium-build"
                  checked={bodyType === "medium-build"}
                  onChange={(e) => onchangebodytype("medium-build")}
                />
                <Mediumslim />
              </label>
              Medium Build
            </div>

            <div
              className={
                bodytypefun() === false
                  ? "adjudt"
                  : bodytypefun()["broad-build"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "13px" }}
            >
              <label
                className={
                  bodyType["broad-build"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="BroadBuild"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="broad-build"
                  id="BroadBuild"
                  value="broad-build"
                  checked={bodyType === "broad-build"}
                  onChange={() => onchangebodytype("broad-build")}
                />
                <Broad />
              </label>
              Broad Build
            </div>
          </div>

          {
            error && !bodyType ? <p className="errorstyle">*Required</p> : ""
          }
          <hr style={{ border: "1px solid #ffd700" }} />
        </div>

        <div id="userskintype">
          <div className="d-flex justify-content-between">
            <label className="fs-7 fw-bold">
              2. How would you best describe your skin?
            </label>
            {/*<img src={alertcircle} className="infoIcon"></img>*/}
          </div>

          <div className="d-flex justify-content-between mt-3">
            <div
              className={
                skintypefun() === false
                  ? "adjudt"
                  : skintypefun()["thin-dry-cold"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px" }}
            >
              <label
                className={
                  skinType["thin-dry-cold"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="Thin"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="thin-dry-cold"
                  id="Thin"
                  value="thin-dry-cold"
                  checked={skinType === "thin-dry-cold"}
                  onChange={() => onchangeSkintype("thin-dry-cold")}
                />
                <Thindrycold />
              </label>
              Thin / Dry / Cold
            </div>

            <div
              className={
                skintypefun() === false
                  ? "adjudt"
                  : skintypefun()["smooth-oily-warm"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px" }}
            >
              <label
                className={
                  skinType["smooth-oily-warm"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="Smooth"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="smooth-oily-warm"
                  id="Smooth"
                  value="smooth-oily-warm"
                  checked={skinType === "smooth-oily-warm"}
                  onChange={() => onchangeSkintype("smooth-oily-warm")}
                />
                <Smoothoily />
              </label>
              Smooth / Oily / Warm
            </div>

            <div
              className={
                skintypefun() === false
                  ? "adjudt"
                  : skintypefun()["thick-oily-cool"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px" }}
            >
              <label
                className={
                  skinType["thick-oily-cool"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="Thick"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="thick-oily-cool"
                  id="Thick"
                  value="thick-oily-cool"
                  checked={skinType === "thick-oily-cool"}
                  onChange={() => onchangeSkintype("thick-oily-cool")}
                />
                <Thickdrycool />
              </label>
              Thick / Oily / Cool
            </div>
          </div>
          {
            error && !skinType ? <p className="errorstyle">*Required</p> : ""
          }
          <hr style={{ border: "1px solid #ffd700" }} />
        </div>

        <div id="userhairtype">
          <div className="d-flex justify-content-between">
            <label className="fs-7 fw-bold">3. How would you best describe your hair?</label>
            {/*<img src={alertcircle} className="infoIcon"></img>*/}
          </div>

          <div className="d-flex justify-content-between mt-3">
            <div
              className={
                hairtypefun() === false
                  ? "adjudt"
                  : hairtypefun()["thin-dry"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px", marginLeft: "15px" }}
            >
              <label
                className={
                  hairType["thin-dry"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="ThinDry"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="thin-dry"
                  id="ThinDry"
                  value="thin-dry"
                  checked={hairType === "thin-dry"}
                  onChange={() => onchangehairtype("thin-dry")}
                />
                <Thindry />
              </label>
              Thin / Dry
            </div>

            <div
              className={
                hairtypefun() === false
                  ? "adjudt"
                  : hairtypefun()["oily-premature-greying"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px" }}
            >
              <label
                className={
                  hairType["oily-premature-greying"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="OilyGreying"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="oily-premature-greying"
                  id="OilyGreying"
                  value="oily-premature-greying"
                  checked={hairType === "oily-premature-greying"}
                  onChange={() => onchangehairtype("oily-premature-greying")}
                />
                <Oilypremature />
              </label>
              Oily Hair / <br />prone to Premature Greying
            </div>

            <div
              className={
                hairtypefun() === false
                  ? "adjudt"
                  : hairtypefun()["thick-and-oily"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px" }}
            >
              <label
                className={
                  hairType["thick-and-oily"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="ThickandOily"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="thick-and-oily"
                  id="ThickandOily"
                  value="thick-and-oily"
                  checked={hairType === "thick-and-oily"}
                  onChange={() => onchangehairtype("thick-and-oily")}
                />
                <Thickoily />
              </label>
              Thick / Oily
            </div>

          </div>
          {
            error && !hairType ? <p className="errorstyle">*Required</p> : ""
          }
          <hr style={{ border: "1px solid #ffd700" }} />
        </div>

        <div id="userappetite">
          <div className="d-flex justify-content-between">
            <label className="fs-7 fw-bold">
              4. How is your appetite?
            </label>
            {/*<img src={alertcircle} className="infoIcon"></img>*/}
          </div>

          <div className="d-flex justify-content-between mt-3">
            <div
              className={
                appetitefun() === false
                  ? "adjudt"
                  : appetitefun()["variable-and-irregular"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px", textAlign: "center" }}
            >
              <label
                className={
                  appetite["variable-and-irregular"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="Variableandirregular"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="variable-and-irregular"
                  id="Variableandirregular"
                  value="variable-and-irregular"
                  checked={appetite === "variable-and-irregular"}
                  onChange={() => onchangeAppetite("variable-and-irregular")}
                />
                <Lowappetite />
              </label>
              Irregular /<br /> unpredictable
            </div>

            <div
              className={
                appetitefun() === false
                  ? "adjudt"
                  : appetitefun()["strong-and-unbearable"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px", textAlign: "center", marginRight: "20px" }}
            >
              <label
                className={
                  appetite["strong-and-unbearable"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="StrongandUnbearable"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="strong-and-unbearable"
                  id="StrongandUnbearable"
                  value="strong-and-unbearable"
                  checked={appetite === "strong-and-unbearable"}
                  onChange={() => onchangeAppetite("strong-and-unbearable")}
                />
                <Strongappetite />
              </label>
              Strong
            </div>

            <div
              className={
                appetitefun() === false
                  ? "adjudt"
                  : appetitefun()["costant-and-low"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px" }}
            >
              <label
                className={
                  appetite["costant-and-low"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="CostantandLow"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="costant-and-low"
                  id="CostantandLow"
                  value="costant-and-low"
                  checked={appetite === "costant-and-low"}
                  onChange={() => onchangeAppetite("costant-and-low")}
                />
                <Constantappetite />
              </label>
              Less appetite
            </div>
          </div>
          {
            error && !appetite ? <p className="errorstyle">*Required</p> : ""
          }
          <hr style={{ border: "1px solid #ffd700" }} />
        </div>


        <div id="userdigestion">
          <div className="d-flex justify-content-between">
            <label className="fs-7 fw-bold">
              5. How is your digestion?
            </label>
            {/*<img src={alertcircle} className="infoIcon"></img>*/}
          </div>

          <div className="d-flex justify-content-between mt-3">
            <div
              className={
                digestionfun() === false
                  ? "adjudt"
                  : digestionfun()["irregular-with-bloating"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px", textAlign: "center" }}
            >
              <label
                className={
                  digestion["irregular-with-bloating"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="Irregularwithbloating"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="irregular-with-bloating"
                  id="Irregularwithbloating"
                  value="irregular-with-bloating"
                  checked={digestion === "irregular-with-bloating"}
                  onChange={() => onchangeDigestion("irregular-with-bloating")}
                />
                <Lowdigestion />
              </label>
              Variable /  Irregular <br /> / Prone to Bloating
            </div>

            <div
              className={
                digestionfun() === false
                  ? "adjudt"
                  : digestionfun()["quick-prone-to-a-burning-sensation"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px", marginLeft: "20px", textAlign: "center" }}
            >
              <label
                className={
                  digestion["quick-prone-to-a-burning-sensation"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="Quickburningsensation"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="quick-prone-to-a-burning-sensation"
                  id="Quickburningsensation"
                  value="quick-prone-to-a-burning-sensation"
                  checked={digestion === "quick-prone-to-a-burning-sensation"}
                  onChange={(e) => onchangeDigestion("quick-prone-to-a-burning-sensation")}
                />
                <Quickdigestion />
              </label>
              Quick
            </div>

            <div
              className={
                digestionfun() === false
                  ? "adjudt"
                  : digestionfun()["prolonged-with-heaviness"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px", textAlign: "center" }}
            >
              <label
                className={
                  digestion["prolonged-with-heaviness"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="Prolongedwithheaviness"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="prolonged-with-heaviness"
                  id="Prolongedwithheaviness"
                  value="prolonged-with-heaviness"
                  checked={digestion === "prolonged-with-heaviness"}
                  onChange={() => onchangeDigestion("prolonged-with-heaviness")}
                />
                <Prolongeddigestion />
              </label>
              Slow
            </div>
          </div>
          {
            error && !digestion ? <p className="errorstyle">*Required</p> : ""
          }
          <hr style={{ border: "1px solid #ffd700" }} />
        </div>




        <div id="userstool">
          <div className="d-flex justify-content-between">
            <label className="fs-7 fw-bold">
              6. How is your stool?
            </label>
            {/*<img src={alertcircle} className="infoIcon"></img>*/}
          </div>

          <div className="d-flex justify-content-between mt-3">
            <div
              className={
                stoolfun() === false
                  ? "adjudt"
                  : stoolfun()["dry-hard-prone-to-constipation"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px", textAlign: "center" }}
            >
              <label
                className={
                  stool["dry-hard-prone-to-constipation"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="Dry,Hard,prone"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="dry-hard-prone-to-constipation"
                  id="Dry,Hard,prone"
                  value="dry-hard-prone-to-constipation"
                  checked={stool === "dry-hard-prone-to-constipation"}
                  onChange={() => onchangestool("dry-hard-prone-to-constipation")}
                />
                <Low />
              </label>
              Mostly Constipated
            </div>

            <div
              className={
                stoolfun() === false
                  ? "adjudt"
                  : stoolfun()["loose-prone-to-diarrhea-with-burning-sensation"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px", textAlign: "center" }}
            >
              <label
                className={
                  stool["loose-prone-to-diarrhea-with-burning-sensation"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="Loose,pronetodiarrhea"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="loose-prone-to-diarrhea-with-burning-sensation"
                  id="Loose,pronetodiarrhea"
                  value="loose-prone-to-diarrhea-with-burning-sensation"
                  checked={stool === "loose-prone-to-diarrhea-with-burning-sensation"}
                  onChange={() => onchangestool("loose-prone-to-diarrhea-with-burning-sensation")}
                />
                <Looseprone />
              </label>
              Mostly Loose
            </div>

            <div
              className={
                stoolfun() === false
                  ? "adjudt"
                  : stoolfun()["moderate-solid-sometimes-pale-in-colour"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px", textAlign: "center" }}
            >
              <label
                className={
                  stool["moderate-solid-sometimes-pale-in-colour"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="Moderate,solid,paleincolour"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="moderate-solid-sometimes-pale-in-colour"
                  id="Moderate,solid,paleincolour"
                  value="moderate-solid-sometimes-pale-in-colour"
                  checked={stool === "moderate-solid-sometimes-pale-in-colour"}
                  onChange={() => onchangestool("moderate-solid-sometimes-pale-in-colour")}
                />
                <Moderate />
              </label>
              Normal
            </div>
          </div>
          {
            error && !stool ? <p className="errorstyle">*Required</p> : ""
          }
          <hr style={{ border: "1px solid #ffd700" }} />
        </div>

        <div id="usersensitivety">
          <div className="d-flex justify-content-between">
            <label className="fs-7 fw-bold">7. Are you sensitive to the following?</label>
            {/*<img src={alertcircle} className="infoIcon"></img>*/}
          </div>

          <div className="d-flex justify-content-between mt-3">
            <div
              className={
                sensitivityfun() === false
                  ? "adjudt"
                  : sensitivityfun()["fear-of-cold-sensetive-to-dryness"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px", textAlign: "center" }}
            >
              <label
                className={
                  sensitivity["fear-of-cold-sensetive-to-dryness"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="Fearofcold,sensetivetodryness"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="fear-of-cold-sensetive-to-dryness"
                  id="Fearofcold,sensetivetodryness"
                  value="fear-of-cold-sensetive-to-dryness"
                  checked={sensitivity === "fear-of-cold-sensetive-to-dryness"}
                  onChange={() => onchangeSensitivity("fear-of-cold-sensetive-to-dryness")}
                />
                <Coldsensitive />
              </label>
              Sensitive to <br />cold and dryness
            </div>

            <div
              className={
                sensitivityfun() === false
                  ? "adjudt"
                  : sensitivityfun()["fear-of-heat-dislike-for-sun"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px", textAlign: "center" }}
            >
              <label
                className={
                  sensitivity["fear-of-heat-dislike-for-sun"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="Fearofheat"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="fear-of-heat-dislike-for-sun"
                  id="Fearofheat"
                  value="fear-of-heat-dislike-for-sun"
                  checked={sensitivity === "fear-of-heat-dislike-for-sun"}
                  onChange={() => onchangeSensitivity("fear-of-heat-dislike-for-sun")}
                />
                <Heatdislike />
              </label>
              Fear of heat <br />/ dislike for sun
            </div>

            <div
              className={
                sensitivityfun() === false
                  ? "adjudt"
                  : sensitivityfun()["fear-of-cold-and-damp"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px", textAlign: "center" }}
            >
              <label
                className={
                  sensitivity["fear-of-cold-and-damp"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="Fearofcold"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="fear-of-cold-and-damp"
                  id="Fearofcold"
                  value="fear-of-cold-and-damp"
                  checked={sensitivity === "fear-of-cold-and-damp"}
                  onChange={() => onchangeSensitivity("fear-of-cold-and-damp")}
                />
                <Fearofcold />
              </label>
              Sensitive to <br />cold and dampness
            </div>

          </div>
          {
            error && !sensitivity ? <p className="errorstyle">*Required</p> : ""
          }
          <hr style={{ border: "1px solid #ffd700" }} />
        </div>

        <div id="usersleep">
          <div className="d-flex justify-content-between">
            <label className="fs-7 fw-bold">
              8. How is your sleep?
            </label>
            {/*<img src={alertcircle} className="infoIcon"></img>*/}
          </div>

          <div className="d-flex justify-content-between mt-3">
            <div
              className={
                sleepfun() === false
                  ? "adjudt"
                  : sleepfun()["light"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px", marginLeft: "20px" }}
            >
              <label
                className={
                  sleep["light"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="light"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="light"
                  id="light"
                  value="light"
                  checked={sleep === "light"}
                  onChange={() => onchangeSleep("light")}
                />
                <Light />
              </label>
              Light
            </div>

            <div
              className={
                sleepfun() === false
                  ? "adjudt"
                  : sleepfun()["moderate-but-sound"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px" }}
            >
              <label
                className={
                  sleep["moderate-but-sound"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="ModeratebutSound"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="moderate-but-sound"
                  id="ModeratebutSound"
                  value="moderate-but-sound"
                  checked={sleep === "moderate-but-sound"}
                  onChange={() => onchangeSleep("moderate-but-sound")}
                />
                <Moderatesound />
              </label>
              Moderate <br />but Sound
            </div>

            <div
              className={
                sleepfun() === false
                  ? "adjudt"
                  : sleepfun()["heavy-and-prolonged"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px" }}
            >
              <label
                className={
                  sleep["heavy-and-prolonged"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="HeavyandProlonged"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="heavy-and-prolonged"
                  id="HeavyandProlonged"
                  value="heavy-and-prolonged"
                  checked={sleep === "heavy-and-prolonged"}
                  onChange={() => onchangeSleep("heavy-and-prolonged")}
                />
                <Heavypronologed />
              </label>
              Heavy and <br />Prolonged
            </div>

          </div>
          {
            error && !sleep ? <p className="errorstyle">*Required</p> : ""
          }
          <hr style={{ border: "1px solid #ffd700" }} />
        </div>


        <div id="useractivity">
          <div className="d-flex justify-content-between">
            <label className="fs-7 fw-bold">9. How would you rate your activity?</label>
            {/*<img src={alertcircle} className="infoIcon"></img>*/}
          </div>

          <div className="d-flex justify-content-between mt-3">
            <div
              className={
                Activityfun() === false
                  ? "adjudt"
                  : Activityfun()["quick-and-fast"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px" }}
            >
              <label
                className={
                  activity["quick-and-fast"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="QuickandFast"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="quick-and-fast"
                  id="QuickandFast"
                  value="quick-and-fast"
                  checked={activity === "quick-and-fast"}
                  onChange={() => onchangeactiivity("quick-and-fast")}
                />
                <Quickfast />
              </label>
              Quick and Fast
            </div>

            <div
              className={
                Activityfun() === false
                  ? "adjudt"
                  : Activityfun()["medium-goal-seeking"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px", textAlign: "center" }}
            >
              <label
                className={
                  activity["medium-goal-seeking"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="Medium,GoalSeeking"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="medium-goal-seeking"
                  id="Medium,GoalSeeking"
                  value="medium-goal-seeking"
                  checked={activity === "medium-goal-seeking"}
                  onChange={() => onchangeactiivity("medium-goal-seeking")}
                />
                <Mediumgoal />
              </label>
              Medium,<br />Goal Seeking
            </div>

            <div
              className={
                Activityfun() === false
                  ? "adjudt"
                  : Activityfun()["slow-and-steady"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px" }}
            >
              <label
                className={
                  activity["slow-and-steady"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="SlowandSteady"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="slow-and-steady"
                  id="SlowandSteady"
                  value="slow-and-steady"
                  checked={activity === "slow-and-steady"}
                  onChange={() => onchangeactiivity("slow-and-steady")}
                />
                <Slowsteady />
              </label>
              Slow and Steady
            </div>


          </div>
          {
            error && !activity ? <p className="errorstyle">*Required</p> : ""
          }
          <hr style={{ border: "1px solid #ffd700" }} />
        </div>

        <div id="userendurance">
          <div className="d-flex justify-content-between">
            <label className="fs-7 fw-bold">
              10. How is your endurance?
            </label>
            {/*<img src={alertcircle} className="infoIcon"></img>*/}
          </div>

          <div className="d-flex justify-content-between mt-3">
            <div
              className={
                endurencefun() === false
                  ? "adjudt"
                  : endurencefun()["poor-endurance"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px" }}
            >
              <label
                className={
                  endurance["poor-endurance"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="Poorendurance"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="poor-endurance"
                  id="Poorendurance"
                  value="poor-endurance"
                  checked={endurance === "poor-endurance"}
                  onChange={() => onchangeendurance("poor-endurance")}
                />
                <Poor />
              </label>
              Poor endurance
            </div>

            <div
              className={
                endurencefun() === false
                  ? "adjudt"
                  : endurencefun()["medium-endurance"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px" }}
            >
              <label
                className={
                  endurance["medium-endurance"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="Mediumendurance"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="medium-endurance"
                  id="Mediumendurance"
                  value="medium-endurance"
                  checked={endurance === "medium-endurance"}
                  onChange={() => onchangeendurance("medium-endurance")}
                />
                <Mediumendurance />
              </label>
              Medium endurance
            </div>

            <div
              className={
                endurencefun() === false
                  ? "adjudt"
                  : endurencefun()["good-endurance"]
                    ? "adjudt"
                    : "dim adjudt"
              }
              style={{ fontSize: "12px" }}
            >
              <label
                className={
                  endurance["good-endurance"]
                    ? `activebutton imagecontainerdiv`
                    : `bg-white imagecontainerdiv`
                }
                htmlFor="Goodendurance"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="good-endurance"
                  id="Goodendurance"
                  value="good-endurance"
                  checked={endurance === "good-endurance"}
                  onChange={() => onchangeendurance("good-endurance")}
                />
                <Goodendurance />
              </label>
              Good endurance
            </div>
          </div>
          {
            error && !endurance ? <p className="errorstyle">*Required</p> : ""
          }



        </div>

        <br />

        <div className="d-flex justify-content-between pb-5">
          <button className="button" onClick={onPrevious}>
            {" "}
            <img src={leftarrow} /> &nbsp;Previous{" "}
          </button>
          <button className="button" onClick={handleNext}>
            {" "}
            Next &nbsp;
            <img src={rightarrow} />
          </button>
        </div>

        {/* <button onClick={onPrevious}>Previous</button> */}
        {/* <button onClick={onNext}>Next</button> */}
        {/* {hairType && <button onClick={onSubmit}>Submit</button>} */}
        {/* <button onClick={onSubmit}>Submit</button> */}
      </div>

    </>

  );
}

export default Prakrti;