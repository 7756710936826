import React, { useEffect, useState } from "react";
import Header from "../components/header.js";
import Footer from "../components/footer.js";
import { useNavigate } from "react-router-dom";
import step1 from "../images/step1.png";
import step2 from "../images/step2.png";
import step3 from "../images/step3.png";
import step4 from "../images/step4.png";
import bg_body from "../images/bg-body.jpg";
import bnrDesktop from "../images/bnr_desk.png";
import bnrmob from "../images/bnr_mob.png";
import bg_img from "../images/bgr.jpg";
import "../pages/Landing.css";
import FlowStep from '../components/FlowStep.js';
import model from "../images/model.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import moengage from '@moengage/web-sdk';
function Landing() {

    const [utmLink, setUtmLink] = useState('');
    useEffect(() => {
        const queryString = window.location.search;
        let NewUrl = queryString.split("?")[1];
        setUtmLink(NewUrl);
    }, []);

    let navigate = useNavigate();
    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const seconds = currentTime.getSeconds();
    const formattedTime = `${hours}:${minutes}:${seconds}`;
    const timeDate = new Date().toLocaleString()
    useEffect(() => {
        const initializeMoEngage = async () => {
            try {
                await moengage.initialize({ app_id: '00NX753TB9KUN4ILW7FME8NE', debug_logs: 0, cluster: 'DC_3' });
                console.log('MoEngage initialized successfully');
            } catch (error) {
                console.error('Error initializing MoEngage', error);
            }
        };
        initializeMoEngage();

        return () => {
            // Cleanup logic if needed
        };
    }, []);
    const handleButtonClick = () => {
        Moengage.track_event("take the quiz", {
            "Time": formattedTime,
            "date": timeDate
        });
        var ev = {};
        ev.eventInfo = {
            'type': ctConstants.diagtooleve
        };
        ev.category = { 'primaryCategory': ctConstants.engagement }
        ev.subcategory = 'Interest';
        ev.action = ctConstants.diagtoolcta
        ev.label = "Started"
        digitalData.event.push(ev);
        if (utmLink) {
            navigate(`/hairquiz?${utmLink}`);
        } else {
            navigate(`/hairquiz`);
        }
    };

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        dots: true
    };
    var viewportWidth = window.innerWidth || document.documentElement.clientWidth;

    return (
        <div>
            <Header />
            <div className='img_Wrapper'>
                <div className='img_bg' style={{ backgroundImage: `url(${bg_body})` }}>
                    {/* <img className='img_banner' src={bg_body} width='100%' height="100%" /> */}

                    <div className='img_inner pb-5'>
                        <div className='container-fluid'>
                            <div className='row linewrapper'>
                                <div className='col-12 col-md-6'>
                                    <img src={model} width="100%" />
                                </div>
                                <div className='col-12 col-md-6 px-4 '>
                                    <h2 className='font-weight-bold pt-4 text-capitalize '>Know your dosha <br />to achieve healthy hair</h2>
                                    <p className='pt-3 font_cstm'>Uplift your hair & scalp health by targeting the root cause of the problem. Take this short Quiz to :</p>

                                    <ul className='font_cstm'>
                                        <li>Get your Prakriti Analysis report & understand your Dosha (Body-Type)</li>
                                        <li>Receive a detailed lifestyle and diet plan tailored to your Dosha</li>
                                        <li>Get personalized product recommendations to improve your hair health</li>
                                    </ul>
                                    <div className='btnCen'>
                                        <button class="lang_button top_btn mb-3" onClick={handleButtonClick}>Take the quiz</button>
                                    </div>
                                </div>
                            </div>
                            {
                                viewportWidth < 576 ? <div className='row py-5'>
                                    <Slider {...settings}>
                                        <div className='col-md-3 col-12'>
                                            <FlowStep step="step 1:" title="Complete this quick 5-minute test!" image={step1} />
                                        </div>
                                        <div className='col-md-3 col-12'>
                                            <FlowStep step="step 2:" title="identify your ayurvedic dosha" image={step2} />
                                        </div>
                                        <div className='col-md-3 col-12'>
                                            <FlowStep step="step 3:" title="tailor-made lifestyle & diet plan based on your dosha" image={step3} />
                                        </div>
                                        <div className='col-md-3 col-12'>
                                            <FlowStep step="step 4:" title="get expert product recommendations" image={step4} />
                                        </div>
                                    </Slider>
                                </div> : <div className='row pt-5'>

                                    <div className='col-md-3 col-12'>
                                        <FlowStep step="step 1:" title="Complete this quick 5-minute test!" image={step1} />
                                    </div>
                                    <div className='col-md-3 col-12'>
                                        <FlowStep step="step 2:" title="identify your ayurvedic dosha" image={step2} />
                                    </div>
                                    <div className='col-md-3 col-12'>
                                        <FlowStep step="step 3:" title="tailor-made lifestyle & diet plan based on your dosha" image={step3} />
                                    </div>
                                    <div className='col-md-3 col-12'>
                                        <FlowStep step="step 4:" title="get expert product recommendations" image={step4} />
                                    </div>

                                </div>
                            }



                        </div>
                    </div>
                </div>
            </div>
            <div className='img_Wrapper'>
                <div className='img_bg'>
                    {viewportWidth > 576 ?
                        <img onClick={handleButtonClick} src={bnrDesktop} width="100%" /> :
                        <img onClick={handleButtonClick} src={bnrmob} width='100%' />
                    }
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Landing
