import React from 'react';
import "./footer.css";
import Logo from "../images/footerlogo.png";
import BB_LOGO from "../images/BB_Logo.png";
import bb from "../images/bb.svg";
import { Link, useNavigate } from "react-router-dom";
function footer() {
  var viewportWidth = window.innerWidth || document.documentElement.clientWidth;

  const handleButtonClick = (item) => {
    if(viewportWidth < 576){
  let tabsHeading = item.target;
    let tabHeading = document.querySelectorAll('.tabsHeading');
    tabHeading.forEach((otherHeading)=>{
      otherHeading.classList.remove('classHeading')
    })
   let currentContent = item.target.nextElementSibling;
   currentContent.classList.remove('activeDisplay');
   var tabsContent = document.querySelectorAll('.tabsContent');
   tabsContent.forEach(item =>{
    item.classList.remove('activeDisplay');
   })
   currentContent.classList.add('activeDisplay');
   tabsHeading.classList.add('classHeading');
  }
};
  return (
    <div className='footer'>
      <div className='container '>
        <div className='row'>
          <div className='col-lg-3 col-md-4 col-sm-4 col-12 '>
            <img src={Logo} className="logo-image" />
            <div className='mt-3'>
              <p className='footer_font'>Hindustan Unilever Limited,</p>
              <p className='footer_font'>Unilever House, B. D. Sawant Marg,</p>
              <p className='footer_font'>Chakala, Andheri (E),</p>
              <p className='footer_font'>Mumbai - 400099</p>
            </div>
            <div>
              <span>Partnered By:</span>
              <span>
                <Link to="https://www.bebeautiful.in/">
                  <img src={BB_LOGO} height="" width="150px" />
                </Link>
              </span>
            </div>
          </div>
          <div className='col-lg-2 col-md-4 col-sm-4 col-12 '>
            <h6 className='tabsHeading' onClick={handleButtonClick}>Information</h6>
            <ul className='list-unstyled tabsContent'>
              <li><Link className="footer_font" to="https://www.indulekha.co.in/pages/understanding-hair-loss">Understanding Hair Loss</Link></li>
              <li><Link className="footer_font" to="https://www.indulekha.co.in/pages/ingredients">Ingredients</Link></li>
              <li><Link className="footer_font" to="https://www.indulekha.co.in/pages/about-us">About Us</Link></li>
              <li><Link className="footer_font" to="https://notices.unilever.com/en/">Accessibility</Link></li>
              <li><Link className="footer_font" to="https://www.unilevernotices.com/privacy-notices/india-english.html">Privacy Notice</Link></li>
              <li><Link className="footer_font" to="https://www.indulekha.co.in/pages/terms-conditions">Terms & Conditions</Link></li>
              <li><Link className="footer_font" to="https://www.indulekha.co.in/policies/terms-of-service">Terms of Service</Link></li>
            </ul>
          </div>
          <div className='col-lg-2 col-md-4 col-sm-4 col-12 '><h6 className='tabsHeading' onClick={handleButtonClick}>Help</h6>
            <ul className='list-unstyled tabsContent'>
              <li><Link className="footer_font" to="https://www.indulekha.co.in/pages/testimonial">Testimonials</Link></li>
              <li><Link className="footer_font" to="https://www.indulekha.co.in/pages/faqs">FAQ's</Link></li>
              <li><Link className="footer_font" to="https://www.indulekha.co.in/pages/contact-us">Enquiry & Info</Link></li>
              <li><Link className="footer_font" to="https://www.indulekha.co.in/pages/site-map-new">Sitemap</Link></li>
              <li><Link className="footer_font" to="https://www.indulekha.co.in/pages/return-policy">Return Policy</Link></li>
              <li><Link className="footer_font" to="https://www.indulekha.co.in/policies/refund-policy">Refund Policy</Link></li>
              <li><Link className="footer_font" to="https://www.indulekha.co.in/pages/shipping-policy">Shipping Policy</Link></li>
              <li><Link className="footer_font" to="https://www.unilevernotices.com/cookie-notices/india-english.html">Cookie Notice</Link></li>
              <li><Link className="footer_font" to="https://www.indulekha.co.in/pages/track-order-page">Track order</Link></li>

            </ul>
          </div>
          <div className='col-lg-3 col-md-4 col-sm-4 col-12 '><h6 className='tabsHeading' onClick={handleButtonClick}>Hair Product</h6>
            <ul className='list-unstyled tabsContent'>
              <li><Link className="footer_font" to="https://www.indulekha.co.in/products/bringha-ayurvedic-hair-growth-oil-100ml">Indulekha Bringha Hair Oil</Link></li>
              <li><Link className="footer_font" to="https://www.indulekha.co.in/products/bringha-ayurvedic-hairfall-shampoo-200ml">Indulekha Bringha Hair Shampoo</Link></li>
              <li><Link className="footer_font" to="https://www.indulekha.co.in/products/indulekha-bringha-hair-strengthening-lepam-200ml">Indulekha Bringha Hair Lepam</Link></li>
              <li><Link className="footer_font" to="https://www.indulekha.co.in/products/indulekha-bringha-hair-strengthening-serum-30ml">Indulekha Bringha Hair Serum</Link></li>
              <li><Link className="footer_font" to="https://www.indulekha.co.in/products/svetakutaja-ayurvedic-anti-dandruff-oil-100ml">Indulekha Svetakutaja (Anti-Dandruff) Hair Oil - 100ml</Link></li>
            </ul>
          </div>
          <div className='col-lg-2 col-md-4 col-sm-2 col-sm-4 col-12 '><h6 className='tabsHeading' onClick={handleButtonClick}>Contact Us</h6>
          <div className='tabsContent'>
            <ul className='social-media--list list-unstyled d-flex  '>
              <li className='social-media__item'>
                <Link to="https://www.facebook.com/IndulekhaCare/">
                  <svg aria-hidden="true" focusable="false" width="24" class="icon icon-facebook" viewBox="0 0 24 24">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.183 21.85v-8.868H7.2V9.526h2.983V6.982a4.17 4.17 0 0 1 4.44-4.572 22.33 22.33 0 0 1 2.667.144v3.084h-1.83a1.44 1.44 0 0 0-1.713 1.68v2.208h3.423l-.447 3.456h-2.97v8.868h-3.57Z" fill="currentColor"></path>
                  </svg>
                </Link>
              </li>

              <li className='social-media__item'>
                <Link to="https://twitter.com/IndulekhaCare">
                  <svg aria-hidden="true" focusable="false" width="24" class="icon icon-twitter" viewBox="0 0 24 24">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.414 4.96c1.506-.024 2.307.479 3.072 1.117.65-.05 1.495-.383 1.992-.614l.484-.242c-.285.702-.67 1.253-1.262 1.67-.131.093-.262.218-.426.277v.009c.843-.008 1.539-.357 2.2-.546v.01a5.934 5.934 0 0 1-1.318 1.384l-.607.442c.01.817-.014 1.598-.18 2.285-.971 3.995-3.544 6.707-7.616 7.868-1.462.418-3.825.59-5.5.208-.83-.188-1.581-.402-2.285-.684a9.54 9.54 0 0 1-1.1-.519l-.342-.19c.378.01.82.105 1.243.043.382-.056.757-.042 1.11-.113.879-.176 1.66-.41 2.332-.77.326-.175.82-.38 1.053-.632a3.59 3.59 0 0 1-1.157-.19c-1.258-.407-1.99-1.154-2.466-2.277.381.038 1.479.129 1.735-.07-.48-.023-.94-.278-1.27-.467-1.012-.58-1.837-1.551-1.83-3.047l.398.173c.254.098.512.15.815.208.128.024.384.093.531.043h-.019c-.195-.208-.514-.347-.71-.571-.65-.739-1.258-1.875-.873-3.229.097-.343.252-.646.417-.926l.019.009c.076.144.244.25.35.372.332.376.741.714 1.158 1.013 1.42 1.016 2.698 1.64 4.75 2.103.522.117 1.124.207 1.746.208-.175-.466-.119-1.22.019-1.67.346-1.134 1.097-1.952 2.2-2.39.263-.105.556-.18.863-.242l.474-.052Z" fill="currentColor"></path>
                  </svg>
                </Link>
              </li>
              <li className='social-media__item'>
                <Link to="https://www.instagram.com/indulekha_care/?utm_medium">
                  <svg aria-hidden="true" focusable="false" width="24" class="icon icon-instagram" viewBox="0 0 24 24" >
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.4c-2.607 0-2.934.011-3.958.058-1.022.046-1.72.209-2.33.446a4.705 4.705 0 0 0-1.7 1.107 4.706 4.706 0 0 0-1.108 1.7c-.237.611-.4 1.31-.446 2.331C2.41 9.066 2.4 9.392 2.4 12c0 2.607.011 2.934.058 3.958.046 1.022.209 1.72.446 2.33a4.706 4.706 0 0 0 1.107 1.7c.534.535 1.07.863 1.7 1.108.611.237 1.309.4 2.33.446 1.025.047 1.352.058 3.959.058s2.934-.011 3.958-.058c1.022-.046 1.72-.209 2.33-.446a4.706 4.706 0 0 0 1.7-1.107 4.706 4.706 0 0 0 1.108-1.7c.237-.611.4-1.31.446-2.33.047-1.025.058-1.352.058-3.959s-.011-2.934-.058-3.958c-.047-1.022-.209-1.72-.446-2.33a4.706 4.706 0 0 0-1.107-1.7 4.705 4.705 0 0 0-1.7-1.108c-.611-.237-1.31-.4-2.331-.446C14.934 2.41 14.608 2.4 12 2.4Zm0 1.73c2.563 0 2.867.01 3.88.056.935.042 1.443.199 1.782.33.448.174.768.382 1.104.718.336.336.544.656.718 1.104.131.338.287.847.33 1.783.046 1.012.056 1.316.056 3.879 0 2.563-.01 2.867-.056 3.88-.043.935-.199 1.444-.33 1.782a2.974 2.974 0 0 1-.719 1.104 2.974 2.974 0 0 1-1.103.718c-.339.131-.847.288-1.783.33-1.012.046-1.316.056-3.88.056-2.563 0-2.866-.01-3.878-.056-.936-.042-1.445-.199-1.783-.33a2.974 2.974 0 0 1-1.104-.718 2.974 2.974 0 0 1-.718-1.104c-.131-.338-.288-.847-.33-1.783-.047-1.012-.056-1.316-.056-3.879 0-2.563.01-2.867.056-3.88.042-.935.199-1.443.33-1.782.174-.448.382-.768.718-1.104a2.974 2.974 0 0 1 1.104-.718c.338-.131.847-.288 1.783-.33C9.133 4.14 9.437 4.13 12 4.13Zm0 11.07a3.2 3.2 0 1 1 0-6.4 3.2 3.2 0 0 1 0 6.4Zm0-8.13a4.93 4.93 0 1 0 0 9.86 4.93 4.93 0 0 0 0-9.86Zm6.276-.194a1.152 1.152 0 1 1-2.304 0 1.152 1.152 0 0 1 2.304 0Z" fill="currentColor"></path>
                  </svg>
                </Link>
              </li>
              <li className='social-media__item'>
                <Link to="https://www.youtube.com/user/shopify">
                  <svg aria-hidden="true" focusable="false" width="24" class="icon icon-youtube" viewBox="0 0 24 24">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.44 5.243c.929.244 1.66.963 1.909 1.876.451 1.654.451 5.106.451 5.106s0 3.452-.451 5.106a2.681 2.681 0 0 1-1.91 1.876c-1.684.443-8.439.443-8.439.443s-6.754 0-8.439-.443a2.682 2.682 0 0 1-1.91-1.876c-.45-1.654-.45-5.106-.45-5.106s0-3.452.45-5.106a2.681 2.681 0 0 1 1.91-1.876c1.685-.443 8.44-.443 8.44-.443s6.754 0 8.438.443Zm-5.004 6.982L9.792 15.36V9.091l5.646 3.134Z" fill="currentColor"></path>
                  </svg>
                </Link>
              </li>
              <li className='social-media__item '>
                <Link to="https://www.bebeautiful.in/">
                  <img src={bb} alt="" srcSet="" />
                </Link>
              </li>
            </ul>
            <div class="text-subdued d-flex">
              <span className=''><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9207 17.8398C14.2746 17.9982 14.6683 18.045 15.0493 17.9739C15.4305 17.9028 15.7808 17.7171 16.0538 17.4417L16.6303 16.8655L20.4966 20.7321L19.3107 21.9197C18.9167 22.3281 18.3607 22.539 17.795 22.4949C9.17787 21.9788 2.02295 14.8233 1.50619 6.20494C1.46226 5.6391 1.67346 5.08328 2.08185 4.68918L3.26946 3.50127L7.13679 7.36916L6.56131 7.94624C6.28593 8.21922 6.10034 8.56969 6.02923 8.95081C5.95831 9.33208 6.00519 9.7258 6.1638 10.0796C7.81083 13.7981 10.2036 16.1909 13.9232 17.8397L13.9207 17.8398ZM18.4728 15.0221L17.6913 15.8037L21.5575 19.6703L22.3775 18.8531C22.461 18.7772 22.5056 18.6675 22.4991 18.5549C22.5042 18.4423 22.4589 18.3333 22.3754 18.2577L19.0614 15.019C18.8971 14.8595 18.6354 14.8609 18.4728 15.0221ZM5.44308 1.50005C5.33152 1.49573 5.22375 1.54053 5.14834 1.6227L4.33003 2.44109L8.19629 6.30915L8.97776 5.5276C9.13931 5.36516 9.1407 5.10343 8.98104 4.93927L5.74318 1.62669C5.66793 1.54296 5.55931 1.49677 5.4467 1.50092L5.44308 1.50005ZM10.0394 6.58799C10.3951 6.23008 10.5957 5.74693 10.5987 5.24247C10.6014 4.73802 10.4062 4.25261 10.0547 3.89075L6.8161 0.577499C6.4603 0.2073 5.96852 -0.00131031 5.45517 6.25311e-05H5.44341C4.93418 -0.00408922 4.44501 0.198656 4.08819 0.561955L1.02173 3.62871C0.308233 4.32519 -0.062443 5.30066 0.00862796 6.29536C0.315309 10.8851 2.29428 15.2025 5.57076 18.4309C8.79876 21.7075 13.1162 23.6862 17.7055 23.9927C17.782 23.9975 17.8576 23.9999 17.9325 23.9999C18.8503 24.0058 19.7308 23.6379 20.3715 22.9807L23.4378 19.9141H23.438C23.8005 19.5518 24.0029 19.0593 24 18.5468C23.9972 18.0342 23.7891 17.5443 23.4224 17.186L20.11 13.9466C19.7481 13.5951 19.2627 13.3998 18.7584 13.4026C18.2538 13.4053 17.7707 13.606 17.4126 13.9615L14.994 16.3801V16.3802C14.8746 16.5076 14.6864 16.5428 14.529 16.4671C11.1766 14.9821 9.01958 12.8244 7.53484 9.47176C7.45891 9.31434 7.49402 9.12595 7.6215 9.00641L10.0394 6.58799Z" fill="white"></path>
              </svg></span>
              <span className='UnderLineText footer_font '><Link to="tel:1800-202-4850">1800-202-4850</Link></span>
            </div>
            <div class="text-subdued d-flex  mt-3  ">
              <span ><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 18" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2801 13.3677C11.2801 13.5664 11.2012 13.7572 11.0606 13.8979C10.9199 14.0385 10.7291 14.1176 10.5302 14.1174H3.54191C3.34055 14.1212 3.14611 14.0439 3.00234 13.9029C2.85858 13.7617 2.77762 13.5689 2.77762 13.3675C2.77762 13.1661 2.85858 12.9731 3.00234 12.8321C3.14609 12.6909 3.34053 12.6136 3.54191 12.6174H10.531C10.7298 12.6176 10.9205 12.6968 11.0609 12.8374C11.2012 12.9781 11.2801 13.1687 11.2801 13.3677ZM22.0773 15.8162H22.0772C22.3102 15.8158 22.4987 15.6271 22.4991 15.3942V2.23127L13.1017 8.80279C12.7798 9.03079 12.3949 9.15345 12.0005 9.15345C11.6059 9.15345 11.2209 9.0308 10.899 8.80279L1.50032 2.23127V15.3942C1.50067 15.6271 1.6894 15.8158 1.92224 15.8162H22.0773ZM20.9276 1.49999L12.2424 7.57375C12.0988 7.68118 11.9018 7.68118 11.7583 7.57375L3.07306 1.49999H20.9276ZM22.0781 0H1.92195C1.41231 0.000691917 0.923973 0.203261 0.563622 0.563621C0.203284 0.923959 0.000708568 1.41249 0 1.92195V15.3945C0.000691961 15.9039 0.203261 16.3925 0.563622 16.7528C0.923959 17.1131 1.41231 17.3157 1.92195 17.3164H22.0781C22.5877 17.3159 23.0762 17.1132 23.4366 16.753C23.7967 16.3926 23.9995 15.9041 24 15.3945V1.92195C23.9995 1.41231 23.7967 0.923796 23.4366 0.563444C23.0762 0.203279 22.5877 0.000531426 22.0781 0Z" fill="white"></path>
              </svg></span>
              <span className='UnderLineText footer_font'><Link to="mailto:order@indulekha.co.in">order@indulekha.co.in</Link></span>
            </div>
            <div class="footer_font mt-3">
            <p>Monday – Sunday</p><p>( except public holiday )</p>
            <p>Time -&nbsp;09.00 am - 06.00 pm</p>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default footer;
