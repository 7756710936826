import React from "react";

const ProgressBar = ({ currentStep, totalSteps }) => {
  const stepNames = [
    "Personal Details",
    "Hair Details",
    "Prakriti",
    "Lifestyle",
  ];
  const stepColors = ["#100505", "#100505", "#100505", "#100505"]; // Define colors for completed steps

  return (
    <>
      <div className="step-progress-bar mx-3">
        {stepNames.map((stepName, index) => (
          <div className={`step-${index}`} key={index}>
            <div
              className={`step-name ${
                currentStep > index + 1 ? "completed" : " "
              }`}
              style={{
                color: currentStep > index + 1 ? stepColors[index] : "#100505",
              }}
            ></div>
            {index < totalSteps - 1 && (
              <div
                className="step-divider"
                style={{
                  backgroundColor:
                    currentStep > index + 1 ? stepColors[index] : "#CFCDCD",
                }}
              ></div>
            )}
          </div>
        ))}
      </div>
      <div className="step-progress-bar mx-3">
        {stepNames.map((stepName, index) => (
          <p key={index}>{stepName}</p>
        ))}
      </div>
    </>
  );
};

export default ProgressBar;
