import React, { useEffect, useState } from "react";
import admin from "../images/admin.jpg";
import rightarrow from "../images/Vector.svg";
import miniLoader from "../images/miniloader.svg";
import "./quiz.css";
import female from "../icons/female.png";
import male from "../icons/male.svg";
import other from "../icons/other.png";
import axios from "axios";
import { Femaleicon, Maleicon, Othericon } from '../svgLibrary.js'

function PersonalDetails({ onNext, handleUserDetails }) {
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [error, seterror] = useState(false);
  const [userStatusPopup, setuserStatusPopup] = useState(false);
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false)
  const [check1, setCheck1] = useState(false)

  const emailValidation = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const nameValidation = /^[a-zA-Z\s-]+$/;
  const numberValidation = /^\d{10}$/;


  const onhandlename = (e) => {
    setName(e.target.value);
    localStorage.setItem("username", e.target.value);
  };

  const onhandleEmail = (e) => {
    const useremail = setEmail(e.target.value);
    localStorage.setItem("useremail", e.target.value);
  };

  const onhandlephone = (e) => {
    // console.log("chreckkkk" , e.target.value)
    localStorage.setItem("userPhone", e.target.value);
    const inputText = e.target.value;
    const numericInput = inputText.replace(/[^0-9]/g, '');
    setPhone(numericInput);
  };
  const AdobeInputError = () => {
    var ev = {};
    ev.eventInfo = {
      'type': ctConstants.trackEvent,
      'eventAction': ctConstants.inputerror,
      'eventLabel': "Input Error",
      'eventValue': 1,
    };
    ev.category = { 'primaryCategory': ctConstants.custom }
    ev.subcategory = 'Error';
    digitalData.event.push(ev)
  }
  const onhandleage = (e) => {
    localStorage.setItem("userage", e.target.value);
    // const inputText = e.target.value;
    // const truncatedInput = inputText.substring(0, 3);
    // setAge(truncatedInput);
  };

  const handleNext = () => {
    window.onscroll = function () { scrollFunction() };
    function scrollFunction() { }
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    const payload = {
      profile: {
        header: {
          isoLanguage: "EN",
          isoCountry: "IN",
          brandCode: "BH8344",
          campaignId: "PN006425",
          campaignDescription: "Always On",
          origin: "https://www.indulekha.co.in/",
          formType: "signUp",
          entity: "PRM2.6"
        },
        consumerIdentity: {
          firstName: name,
          gender: selectedGender
        },
        contactDetail: {
          email: email,
          mobileNumber: `+91${phone}`
        },
        optInStatus: {
          legalAgeConsent: true,
          unileverEmailConsent: true,
          unileverSMSConsent: true
        }
      }
    }
    console.log("payload111" , payload)
    fetch('https://forms-sg.unileversolutions.com/v3', {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json',
        "x-api-key": "5a08971a-7c75-4798-8099-71883efb29f1"
      },
      body: JSON.stringify(payload)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log(data);
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
    // Validate the input, you can add more validation as needed
    if (!nameValidation.test(name)) {
      seterror(true);
      document.getElementById("userName").focus();
      AdobeInputError()
      return false;
    }
    else if (!emailValidation.test(email)) {
      seterror(true);
      document.getElementById("userEmail").focus();
      AdobeInputError()
      return false;
    }
    else if (!numberValidation.test(phone)) {
      seterror(true);
      document.getElementById("userPhone").focus();
      AdobeInputError()
      return false;
    }
    // else if (!check) {
    //   seterror(true)
    // } else if (!check1) {
    //   seterror(true)
    // }

    if (name && age && selectedGender && email && phone) {
      handleUserDetails({ name, age, selectedGender, email, phone, check, check1 });
      setLoading(true);
      var ev = {};
      ev.eventInfo = {
        'type': ctConstants.trackEvent,
        'eventAction': ctConstants.SignUpSubmit,
        'eventLabel': 'Successful Sign Up',
        'eventValue': 1,
      };
      ev.category = { 'primaryCategory': ctConstants.engagement }
      ev.subcategory = 'Lead';
      digitalData.event.push(ev)

      usercheck();
    } else {

    }
  };

  // setTimeout(() => {
  //   setLoading(false);
  // }, 1000);

  const handleGenderChange = (gender) => {
    if (gender == "Male") {
      setSelectedGender("M");
    }
    else if (gender == "Female") {
      setSelectedGender("F");
    }

    localStorage.setItem("gender", gender);
  };

  useEffect(() => {
    const mobile = localStorage.getItem("userPhone");
    const username = localStorage.getItem("username");
    const userage = localStorage.getItem("userage");
    const useremail = localStorage.getItem("useremail");
    const usergender = localStorage.getItem("gender");

    if (!name) {
      setName(username);
    } else if (!email) {
      setEmail(useremail);
    } else if (!phone) {
      setPhone(mobile);
    } else if (!age) {
      setAge(userage);
    } else if (!selectedGender) {
      setSelectedGender(usergender);
    }
  });

  useEffect(() => {
    setTimeout(() => {


      var ev = {};
      ev.eventInfo = {
        'type': ctConstants.trackEvent,
        'eventAction': ctConstants.SignUpstart,
        'eventLabel': "https://hairquiz.indulekha.co.in/hairquiz",
        'eventValue': 1,
      };
      ev.category = { 'primaryCategory': ctConstants.engagement }
      ev.subcategory = 'Lead';
      ev.attributes = { 'nonInteractive': { 'nonInteraction': 1 } };
      digitalData.event.push(ev)
    }, 1000)
  }, []);
  const genderClass = (gender) => {
    return selectedGender === gender ? "activebutton" : "";
  };

  const usercheck = () => {
    console.log("phonephone", phone)
    try {
      let data = JSON.stringify({
        phone: phone,
      });
      console.log("phone data", data);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://api.indulekha.co.in/hairtest/getuser",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log("129", response?.data);
          let userStatus = response?.data?.user;
          console.log()
          let userID = response?.data?.data?._id;
          console.log("ddd", userStatus, typeof userStatus);
          setUserId(userID);
          if (userStatus === true) {
            setuserStatusPopup(true);
            setLoading(false)
          } else if (userStatus === false) {
            onNext();
          } else {

          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) { }
  };




  const abc = () => {
    // location.reload()
    onNext();
    // localStorage.clear("username", "userPhone", "useremail", "userage", "gender")
    // setCheck(false)
    // setCheck1(false)

  }

  const checkbox = () => {
    setCheck(true)
  }
  const checbox1 = () => {
    setCheck1(true)
  }
  return (
    <>
      {loading === true ? (
        <>
          <div className="loaderOverlay"></div>
          <div className="miniloader">
            <img src={miniLoader} alt="" srcSet="" />
          </div>
        </>
      ) : (
        <div className="container" style={{ position: "relative" }}>
          <div className="mb-2">
            <label htmlFor="userName" className="form-label fw-bold mb-1">
              Name*
            </label>
            <input
              type="text"
              className="form-control"
              id="userName"
              placeholder="Enter your Name Here"
              value={name}
              onChange={(e) => onhandlename(e)}
            />
          </div>

          {error && !name ? (
            <p style={{ color: "red", fontSize: "12px" }}>
              Please Enter your name
            </p>
          ) : (
            ""
          )}
          <div className="mb-2 font-weight-bold">
            <label htmlFor="userEmail" className="form-label fw-bold mb-1">
              Email*
            </label>
            <input
              type="email"
              className="form-control"
              id="userEmail"
              placeholder="Enter your Email Here"
              value={email}
              onChange={(e) => {
                onhandleEmail(e);
              }}
            />
          </div>
          {error && !email ? (
            <p style={{ color: "red", fontSize: "12px" }}>
              Please Enter your email
            </p>
          ) : error && email && !emailValidation.test(email) ? (
            <p style={{ color: "red", fontSize: "12px" }}>
              Please Enter a valid email
            </p>
          ) : (
            ""
          )}

          <div className="mb-2">
            <label htmlFor="userPhone" className="form-label fw-bold mb-1">
              Phone Number*
            </label>
            <input
              type="tel"
              className="form-control"
              id="userPhone"
              placeholder="Enter your Phone Here"
              value={phone}
              onChange={(e) => {
                onhandlephone(e);
              }}
              maxLength={10}
            />
          </div>

          {error && !phone ? (
            <p style={{ color: "red", fontSize: "12px" }}>
              Please Enter your phone
            </p>
          ) : (
            ""
          )}

          <div className="mb-2">
            <label htmlFor="userAge" className="form-label fw-bold mb-1">
              Age*
            </label>
            {/* <input
              className="form-control"
              type="number"
              id="userAge"
              value={age}
              placeholder="Enter your Age Here"
              onChange={(e) => {
                onhandleage(e);
              }}
            /> */}
            <div className="d-flex justify-content-around">
              <div className="form-check  d-f justify-content-between align-items-center p-1 m-0">
                <input className="d-none" type="radio" name="age" value="18-24" id="18-24"
                  checked={age === "18-24"} onChange={(e) => {
                    setAge(e.target.value)
                    localStorage.setItem("userage", e.target.value)
                  }} />
                <label htmlFor="18-24" className={`form-label  mb-1 cstm_input ${age === "18-24" ? `activebutton` : ''}`}>
                  18-24
                </label>
              </div>
              <div className="form-check  d-f justify-content-between align-items-center p-1 m-0">
                <input className="d-none" type="radio" name="age" value="25-34" id="25-34"
                  checked={age === "25-34"} onChange={(e) => {
                    setAge(e.target.value)
                    localStorage.setItem("userage", e.target.value)
                  }} />
                <label htmlFor="25-34" className={`form-label  mb-1 cstm_input ${age === "25-34" ? `activebutton` : ''}`}>
                  25-34
                </label>
              </div>
              <div className="form-check  d-f justify-content-between align-items-center p-1 m-0">
                <input className="d-none" type="radio" name="age" value="35-44" id="35-44"
                  checked={age === "35-44"} onChange={(e) => {
                    setAge(e.target.value)
                    localStorage.setItem("userage", e.target.value)
                  }} />
                <label htmlFor="35-44" className={`form-label  mb-1 cstm_input ${age === "35-44" ? `activebutton` : ''}`}>
                  35-44
                </label>
              </div>
              <div className="form-check  d-f justify-content-between align-items-center p-1 m-0">
                <input className="d-none" type="radio" name="age" value="45-54" id="45-54"
                  checked={age === "45-54"} onChange={(e) => {
                    setAge(e.target.value)
                    localStorage.setItem("userage", e.target.value)
                  }} />
                <label htmlFor="45-54" className={`form-label  mb-1 cstm_input ${age === "45-54" ? `activebutton` : ''}`}>
                  45-54
                </label>
              </div>
              <div className="form-check  d-f justify-content-between align-items-center p-1 m-0">
                <input className="d-none" type="radio" name="age" value="55-64" id="55-64"
                  checked={age === "55-64"} onChange={(e) => {
                    setAge(e.target.value)
                    localStorage.setItem("userage", e.target.value)
                  }} />
                <label htmlFor="55-64" className={`form-label  mb-1 cstm_input ${age === "55-64" ? `activebutton` : ''}`}>
                  55-64
                </label>
              </div>
              <div className="form-check  d-f justify-content-between align-items-center p-1 m-0">
                <input className="d-none" type="radio" name="age" value="65+" id="65+"
                  checked={age === "65+"} onChange={(e) => {
                    setAge(e.target.value)
                    localStorage.setItem("userage", e.target.value)
                  }} />
                <label htmlFor="65+" className={`form-label  mb-1 cstm_input ${age === "65+" ? `activebutton` : ''}`}>
                  65+
                </label>
              </div>
            </div>
          </div>

          {error && !age ? (
            <p style={{ color: "red", fontSize: "12px" }}>
              Please Enter your age
            </p>
          ) : (
            ""
          )}

          <label htmlFor="userGender" className="form-label fw-bold">
            Gender*
          </label>
          <div
            className="d-flex justify-content-around"
            id="userGender"

          >
            <div
              className="form-check d-f justify-content-between align-items-center p-0 m-0"
              style={{ fontSize: "14px", height: "78px", width: "78px" }}
            >
              <label
                className={`form-check-label ${genderClass("M")}`}
                htmlFor="exampleRadios1"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios1"
                  value="Male"
                  checked={selectedGender === "Male"}
                  onChange={() => handleGenderChange("Male")}
                />
                <Maleicon />
                <span>Male</span>
              </label>
            </div>

            <div
              className="form-check d-f justify-content-between align-items-center p-0 m-0"
              style={{ fontSize: "14px" }}
            >
              <label
                className={`form-check-label mb-1 ${genderClass("F")}`}
                htmlFor="exampleRadios2"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios2"
                  value="Female"
                  checked={selectedGender === "Female"}
                  onChange={() => handleGenderChange("Female")}
                />
                <Femaleicon />
                <span>Female</span>
              </label>
            </div>
            <div
              className="form-check d-f justify-content-between align-items-center p-0 m-0"
              style={{ fontSize: "14px" }}
            >
              <label
                className={`form-check-label mb-1 ${genderClass("Other")}`}
                htmlFor="exampleRadios3"
              >
                <input
                  className="form-check-input d-none"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios3"
                  value="Other"
                  checked={selectedGender === "Other"}
                  onChange={(e) => handleGenderChange(e.target.value)}
                />
                <Othericon />
                <span>Prefer Not To Say</span>
              </label>
            </div>

          </div>

          <br />
          {error && !selectedGender ? (
            <p style={{ color: "red", fontSize: "12px" }}>
              Please select one..
            </p>
          ) : (
            ""
          )}
          <div >
            <p class="checkboxDataStyle">By proceeding, I agree to the HUL <a href="https://www.indulekha.co.in/pages/terms-conditions" target="_blank">T&C</a> &  <a href="https://www.unilevernotices.com/privacy-notices/india-english.html" target="_blank">Privacy Notice.</a> </p>
          </div>

          {/* <div className="d-flex justify-content-start align-items-start mt-2">
            <input type="checkbox" className="checkboxStyle"
              onClick={checkbox}
              value="check"
            />
            <p className="checkboxDataStyle ms-2"> I agree to receiving marketing communications from the Trusted Unilever Brands on offers, discounts, new products/services, updates, recommendations, etc. as per the <a href="https://www.unilevernotices.com/privacy-notices/india-english.html">Privacy Notice.</a></p>
          </div>

          {error && !check ? (
            <p style={{ color: "red", fontSize: "12px" }}>
              permission required
            </p>
          ) : (
            ""
          )} */}

          <div className="d-flex justify-content-start align-items-start">
            <input type="checkbox" className="checkboxStyle"
              onClick={checbox1}
              value="check1"
            />
            <p className="checkboxDataStyle ms-2">  I agree to receiving marketing communications on offers, products, preferences, etc. from the <a href="https://www.hul.co.in/brands/" target="_blank">Trusted Unilever Brands.</a></p>
          </div>

          {error && !check1 ? (
            <p style={{ color: "red", fontSize: "12px" }}>
              permission required
            </p>
          ) : (
            ""
          )}

          <div className="d-flex justify-content-center align-items-center mt-3 mb-5">
            <button className="button" onClick={handleNext}>
              Next &nbsp;
              <img src={rightarrow} alt="Next" />
            </button>
          </div>

          {userStatusPopup === true ? (
            <>
              <div
                className="UserCheckContainerOverlay"
                onClick={() => setuserStatusPopup(false)}
              ></div>
              <div className="UserCheckContainer">
                <button
                  className="btn close"
                  onClick={() => setuserStatusPopup(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <p className="small text-center">
                  Your phone number is already associated with another hair
                  report. Please access your result or take the hair test with a
                  different number.
                </p>
                <div className="d-flex justify-content-center align-items-center mt-3 mb-5 flex-column">
                  <button className="btn btn-secondary w-100 m-2" onClick={abc}>
                    Take Hair Test Again
                  </button>
                  <a
                    className="btn btn-outline-secondary w-100 m-2"
                    href={`https://www.indulekha.co.in/pages/report?responseid=${userId}`}
                  >
                    Go To My Result
                  </a>
                </div>
              </div>
            </>
          ) : null}
        </div>
      )}
    </>
  );
}

export default PersonalDetails;